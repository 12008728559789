import { Payment } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const tableSlice = createSlice({
  name: "tableData",
  initialState: {
    data: [],
    createCustomer: {
      order_date: new Date(),
      table_name: "",
      saas_id: "8",
      store_id: "80002",
      category: "Pick-up",
      payment_mode: Payment,
      status: "pending",
      order_details: [],
      table_number: null,
      add_customer: [],
    },
  },
  reducers: {
    addData: (state, action) => {
      state.data.push(action.payload);
      // state.data = action.payload
    },
    addCustomer: (state, action) => {
      state.createCustomer.addCustomer.push(action.payload);
    },
    addTableName: (state, action) => {
      state.createCustomer.table_name = action.payload;
    },
    addTableCategory: (state, action) => {
      state.createCustomer.category = action.payload;
    },
    addPaymentMode: (state, action) => {
      state.createCustomer.payment_mode = action.payload;
    },
    addOrderDetails: (state, action) => {
      state.createCustomer.order_details.push(action.payload);
      //   state.data = action.payload
    },
    addCustomerDetails: (state, action) => {
      state.createCustomer.add_customer.push(action.payload);
      //   state.data = action.payload
    },
    addQuantityChange: (state, action) => {
      const updatedItems = [...state.createCustomer.order_details];
      updatedItems[action.payload.index].item_qty = action.payload.newQty;
    },
    addDate: (state, action) => {
      state.createCustomer.order_date = action.payload;
    },
    handleTableNumber: (state, payload) => {
      state.createCustomer.table_number = payload.payload;
    },
  },
});

// for exporting in react componenrt
export const {
  addData,
  handleTableNumber,
  addCustomer,
  addTableName,
  addDate,
  addTableCategory,
  addPaymentMode,
  addOrderDetails,
  addCustomerDetails,
  addQuantityChange,
} = tableSlice.actions;
// for exporting in store(reducer)
export const tableReducer = tableSlice.reducer;

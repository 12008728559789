import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import './CategorySwiper.css'

import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
function CategorySwiper({category,saas_id,store_id,table_id}) {
  const params = useParams();
  const {id}=params
    const navigate = useNavigate();
    const handleData=(link)=>{
        navigate(`/category-name/${saas_id}/${store_id}/${link.category_id}/${table_id}`)
        // setOpen(false)
      }
  return (
    <div className=' bg-dark-purple text-center' style={{height:"50px"}}>  <Swiper
    modules={[Navigation, Pagination, Scrollbar, A11y]}
    spaceBetween={50}
    slidesPerView={3}
    navigation
    pagination={{ clickable: true }}
    scrollbar={{ draggable: true }}
    onSwiper={(swiper) => console.log(swiper)}
    onSlideChange={() => console.log('slide change')}
  >
     {category.length > 0 &&
      category.map((link) => (
        <SwiperSlide  > 
        <Button
         key={link.category_id}
         className={` ${link.category_id==id?  "catgoryselected":"bg-dark-purple"  }`}
          // className='md:ml-8 text-xl md:my-0 my-7 "text-gray-300  flex item-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md mt-2"'
        >
          <Button
            className={`${ link.category_id==id?"text-white":"text-white"} text-nowrap`}
            onClick={() => handleData(link)}
          >
            {link.category_name && link.category_name}
          </Button>
        </Button>
        </SwiperSlide>
      ))}

  </Swiper></div>
  )
}

export default CategorySwiper
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsCheck2 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { BASE_Url } from "../../URL.js/URL";
import { Search } from "@mui/icons-material";
import Swal from "sweetalert2";
import { confirmAlert } from "react-confirm-alert";
import { setAnimation } from "@material-tailwind/react/components/Tabs/TabsContext";
import Select from "react-select";
import { Row, Col } from "reactstrap";
import { Checkbox, FormControlLabel, Paper } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Grid
} from '@mui/material';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/scrollbar';
import AddInventoryItemModal from "../inventory/AddInventoryItemModal";

const AddBom = () => {
  const [category, setCategory] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [allData, setAllData] = useState([]);
  const [allInventoryData, setAllInventoryData] = useState([]);
  const [selectedSearchedData, setSelectedSearchedData] = useState(null);
  const [value, setValue] = useState("");
  const [waist, setWaist] = useState("");
  const [search, setSearch] = useState("");
  const [itemExist, setItemExist] = useState(false);

  const [quantity, setQuantity] = useState("");
  const [cost, setCost] = useState("");
  const [umo, setUmo] = useState("");
  const [item, setItem] = useState([]);
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [name, setName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [CategoryDetail, setCategoryDetail] = useState(null);

  const getCatogery = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .get(`${BASE_Url}/item/category-list/${saasId}`)
      .then((res) => {
        setCategory(res.data.data.splice(0, 7));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getCatogery();
  }, []);

  const getAllData = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .get(`${BASE_Url}/item/view-menu/${selectedCategory}/${saasId}`)
      .then((res) => {
        if (res.status === 200) {
          res.data.data.item_list.forEach((el) => {
            el["value"] = el.item_name;
            el["label"] = el.item_name;
          });
          setAllData(res.data.data.item_list);
        }
      })
      .catch((err) => console.log("GET ALL DATA ERR", err));
  };

  useEffect(() => {
    if (selectedCategory) {
      getAllData();
    }
  }, [selectedCategory]);

  const getAllInventoryList = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .get(
        `${BASE_Url}/inventory-master/get-all-inventory-list/${storeId}/${saasId}`
      )
      .then((res) => {
        if (res.status === 200) {
          res.data.data.forEach((el) => {
            el["value"] = el.inventory_name;
            el["label"] = el.inventory_name;
          });
          setAllInventoryData(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllInventoryList();
  }, []);

  useEffect(() => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    if (search) {
      const apiUrl = `${BASE_Url}/search/get-result/${storeId}/${saasId}/${search}`;
      axios
        .get(apiUrl)
        .then((res) => {
          if (res) {
            res.data.data.forEach((item) => {
              item["label"] = item.item_name;
              item["value"] = item.item_name;
            });
            setItem(res.data.data);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  }, [search]);

  useEffect(() => {
    if (selectedInventory) {
      setName(selectedInventory.inventory_name);
      setCost(selectedInventory.cost);
      setQuantity(selectedInventory.opening_qty);
    }
  }, [selectedInventory]);

  const handleAddMaterialDetail = () => {
    if (name && cost && quantity) {
      const materialData = {
        inventory_id: 987,
        waste_quantity: waist,
        uom: umo,
        reorderLevel: 5.0,
        supplierId: "SUP123",
        raw_material: name,
        quantity,
        cost_of_material: cost,
        expiryDate:""
      };
      setTableData((prevTableData) => [...prevTableData, materialData]);
    }
  };

  const [categoryId, setcategoryId] = useState([]);

  const handleCreateBom = () => {
    console.log("raw materail",tableData.reduce((total, item) => total + item.quantity, 0))
    const totalrawqty = tableData.reduce((total, item) => total + item.quantity, 0)
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .post(`${BASE_Url}/create-bom`, {
        item_id: selectedItem?.item_id,
        item_name: selectedItem?.item_name,
        // price: selectedItem?.price,
        // tax: selectedItem?.tax,
        // discount: selectedItem?.discount,
        child_bom_quantity: totalrawqty,
        // waste_quantity: waist,
        preparation_time: 30,
        cooking_time: 45,
        servings: 4,
        saas_id: saasId,
        store_id: storeId,
        category_details: CategoryDetail,
        raw_material_details: tableData,
      })
      .then((res) => {
        console.log("CREATED BOM", res);
        setTableData([]);
        Swal.fire({
          title: "Item Add Successful",
          icon: "success",
          timer: 1000,
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "This Item AddBom Already Created",
          icon: "error",
        });
        console.log(err);
      });
  };

  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [inventoryShow, setInventoryShow] = useState(false);

  return (
    <>
      <div className="container mt-2" style={{ height: screenWidth >= 768 ? "100%" : "90%", overflowY: "scroll" }}>
        <div
          className="container mb-5 mt-1"
          style={{
            overflowX: "auto",
            boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
            borderRadius: "0.75rem",
            overflow: "hidden",
            fontFamily: "DM sans-serif",
            fontSize: "1.25rem",
            border: "0 2px 12px rgba(36, 36, 39, .12)",
            padding: "10px",
            height: "fit-content"
          }}
        >
          <form>
            <div>
              <div className="flex flex-row items-center justify-center my-2">
                <BsArrowLeft
                  size={25}
                  className="cursor-pointer mx-3"
                  onClick={() => navigate("/inventory")}
                />
                <h3 className="text-start mt-2 font-medium text-xl font-['inter']">
                  Add Bill of Material
                </h3>
              </div>
              <hr className="m-0" />
              <Swiper
                className="mt-3"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                slidesPerView={screenWidth >= 768 ? 6 : 2}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                freeMode={true}
                grabCursor={true}
                watchOverflow={true}
                style={{ zIndex: 0 }}
              >
                {category.map((el) => (
                  <SwiperSlide key={el.category_id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCategory === el.category_id}
                          onChange={() =>{ setSelectedCategory(el.category_id);
                            setCategoryDetail([{ category_id: el.category_id }])
                          }}
                        />
                      }
                      label={el.category_name}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <Grid
              className="mt-2"
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid direction="row" item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={5}>
                    <Select
                      options={allData}
                      onChange={(e) => setSelectedItem(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={1}
                    onClick={() => navigate("/Menu")}
                  >
                    <Button
                      className="text-nowrap"
                      variant="contained"
                      color="primary"
                    >
                      Add Item
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={5}>
                    {allInventoryData.length > 0 && (
                      <Select
                        options={allInventoryData}
                        onChange={(e) => setSelectedInventory(e)}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Button
                      className="text-nowrap"
                      variant="contained"
                      color="primary"
                      onClick={() => setInventoryShow(true)}
                    >
                      Add Inventory
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
          <TableContainer style={{ height: "200px" }} component={Paper}>
            <Table className="table-white table-striped p-2 mt-3 h-10">
              <TableHead>
                <TableRow className="text-nowrap">
                  <TableCell align="center"> Item Name</TableCell>
                  <TableCell align="center"> Consumption Quantity</TableCell>
                  <TableCell align="center"> Waist Quantity</TableCell>
                  <TableCell align="center">Enter Cost of Material</TableCell>
                  <TableCell align="center">Unit</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.length > 0 ? (
                  tableData.map((el, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{el.raw_material}</TableCell>
                      <TableCell align="center">{el.quantity}</TableCell>
                      <TableCell align="center">{el.waste_quantity}</TableCell>
                      <TableCell align="center">{el.cost_of_material}</TableCell>
                      <TableCell align="center">{el.uom}</TableCell>
                      <TableCell align="center">
                        <BsCheck2 />
                      </TableCell>
                    </TableRow>
                  ))
                ) : null}
                <TableRow>
                  <TableCell align="center">
                    <TextField
                      type="text"
                      value={name}
                      placeholder="itemName"
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      aria-describedby="nameHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      value={quantity}
                      placeholder="Consumption Quantity"
                      onChange={(e) => setQuantity(e.target.value)}
                      className="form-control"
                      aria-describedby="quantityHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      value={waist}
                      placeholder="Waist Quantity"
                      onChange={(e) => setWaist(e.target.value)}
                      className="form-control"
                      aria-describedby="waistHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="number"
                      value={cost}
                      placeholder="item Cost"
                      onChange={(e) => setCost(e.target.value)}
                      className="form-control"
                      aria-describedby="costHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      type="text"
                      value={umo}
                      placeholder="unit"
                      onChange={(e) => setUmo(e.target.value)}
                      className="form-control"
                      aria-describedby="costHelp"
                    />
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={handleAddMaterialDetail}
                    >
                      Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            className="mt-1 mb-3"
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                onClick={() => handleCreateBom()}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => navigate("/inventory")} variant="contained" color="secondary">
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>

      <AddInventoryItemModal show={inventoryShow} onHide={() => setInventoryShow(false)} />
    </>
  );
};

export default AddBom;

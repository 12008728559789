import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Typography, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import moment from 'moment/moment';

const StyledCard = styled(Card)({
  maxWidth: '800px',
  margin: 'auto',
  border: '2px solid #b8afaf',
  '& .MuiCardHeader-root': {
    borderBottom: '1px solid #b8afaf',
    '& .MuiTypography-root': {
      color: '#b8afaf',
      fontWeight: 'bold',
    },
  },
});

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#b8afaf',
    },
    '&:hover fieldset': {
      borderColor: '#b8afaf',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#b8afaf',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#b8afaf',
    fontWeight: 'bold',
  },
});

const StyledTableCell = styled(TableCell)({
  color: '#b8afaf',
  fontWeight: 'bold',
});

const Statement = () => {
    const [searchDate, setSearchDate] = useState(moment(Date.now()).format('YYYY-MM-DD'));
    const {saasId, storeId} = JSON.parse(localStorage.getItem('STORE_DATA'));
  const [transactions,setTransactions] = useState([]);

//   const transactions = searchDate
//     ? transactions.filter(t => t.date === searchDate)
//     : transactions;

    //get transactions based on search date api call
    const getAllData = async () => {
        try {
            const apiUrl = `${BASE_Url}/bahikhata/view-bahikhata-details/${saasId}/${storeId}/${searchDate}`;
            const response = await axios.get(apiUrl);
            console.log("API response:", response.data);
            setTransactions(response.data.data);
            
        } catch (error) {
            console.error('Error fetching transactions:', error);
            
        }
    }
    useEffect(() => {
        getAllData();
    }, [searchDate]);

  return (
    <div><StyledCard>
    <CardHeader
      title={<Typography variant="h5" align="center">Business Transactions</Typography>}
    />
    <CardContent>
      <StyledTextField
        label="Search From Date"
        type="date"
        value={searchDate}
        onChange={(e) => setSearchDate(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        margin="normal"
      />
      <TableContainer component={Paper} style={{ height: "250px", marginBottom:"10px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>DATE</StyledTableCell>
              <StyledTableCell>MODE</StyledTableCell>
              <StyledTableCell>TYPE</StyledTableCell>
              <StyledTableCell>Party NAME</StyledTableCell>
              <StyledTableCell>Amount</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>{transaction.paymentDate}</TableCell>
                <TableCell>{transaction.paymentMode}</TableCell>
                <TableCell>{transaction.paymentType}</TableCell>
                <TableCell>{transaction.partyName}</TableCell>
                <TableCell>{transaction.amount.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {transactions.length > 0 && (
        <Typography style={{ marginTop: '20px', color: '#b8afaf' }}>
          <strong>Payment Notes:</strong> {transactions[0].paymentNotes}
        </Typography>
      )}
    </CardContent>
  </StyledCard></div>
  )
}

export default Statement
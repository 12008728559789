import React from "react";
import chola from "../../images/chola.jpg";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { BASE_Url } from "../../URL.js/URL";
import { Card, CardMedia, CardContent, Typography, Button, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';
const AddToCartButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'green',
  '&:hover': {
    backgroundColor: 'darkgreen',
  },
  color: 'white',
}));

const Product = ({ recommandItem, updatecart, setUpdatecart }) => {
  const dispatch = useDispatch();

  const handleAddtocart = (item) => {
    const el = JSON.parse(localStorage.getItem("my-cart"));
    // Swal.fire(
    //   "Good job",
    //   `Your ${item.item_name} is added in cart `,
    //   "success"
    // );
    Swal.fire({
      icon: 'success',
      title: `Your ${item.item_name} is added in cart `,
      showConfirmButton: false,
      timer: 1000
    })
    if (el) {
      if (el?.length > 0) {
        let flag = 0;
        el.map((el1) => {
          if (el1.item_id == item.item_id && el1.item_name == item.item_name) {
            console.log("this console", el);
            if (el1.item_price == item.item_price) {
              el1.item_qty = el1.item_qty + 1;
              flag = 1;
            } else {
              item["item_qty"] = 1;
              item["item_newprice"] = Number(item.item_price);
              localStorage.setItem("my-cart", JSON.stringify([...el, item]));
              setUpdatecart(!updatecart);
            }
          } else {
            item["item_qty"] = 1;
            item["item_newprice"] = Number(item.item_price);
            localStorage.setItem("my-cart", JSON.stringify([...el, item]));
            setUpdatecart(!updatecart);
          }
        });
        el.map((item) => {
          console.log("add qty");
          item["item_newprice"] =
            Number(item.item_price) * Number(item.item_qty);
        });
        localStorage.setItem("my-cart", JSON.stringify(el));
        console.log("length", [...el]?.length);
        setUpdatecart(!updatecart);
        if (flag === 0) {
          console.log("this call");
          item["item_newprice"] = Number(item.price) * Number(item.productQty);
          localStorage.setItem("my-cart", JSON.stringify([...el, item]));
        }
      }
    } else {
      item["item_qty"] = 1;
      item["item_newprice"] = item.item_price;
      localStorage.setItem("my-cart", JSON.stringify([item]));
      setUpdatecart(!updatecart);
    }
  };

  // console.log("this is remmondede", recommandItem)

  return (
    <div style={{ height: "60vh", overflowY: "auto"}}>
      {recommandItem && (
        <div className="mb-5"  >
          {recommandItem?.map((item) => (
         
               <Card className="mt-2 container" style={{ display: 'flex', maxWidth: 345, border: '1px solid #e0e0e0', borderRadius: 8 }}>
          <CardMedia
        component="img"
        style={{ width: 100,height:100,   boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
        borderRadius: "0.75rem", margin: '8px' }}
        image={`${BASE_Url}/item/get-image/${item.item_id}`}
        alt="Chicken"
      />
           <CardContent style={{ flex: '1 0 auto', padding: '16px', textAlign: 'left' }}>
        <Typography variant="h6" component="div">
        {item.item_name}
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ marginBottom: '16px' }}>
        Rs.{item.item_price}
        </Typography>
        <Box textAlign="center">
          <Button
           onClick={() => handleAddtocart(item)}
            variant="contained"
            style={{
              backgroundColor: 'green',
              color: 'white',
              borderRadius: 20,
              padding: '8px 16px',
              textTransform: 'none',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = 'darkgreen')}
            onMouseOut={(e) => (e.target.style.backgroundColor = 'green')}
          >
            Add to Cart
          </Button>
        </Box>
      </CardContent>    
            
                
             
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default Product;

import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import { BASE_Url } from "../../URL.js/URL";
import Grid from '@mui/material/Grid';
import { Container, Box, Typography } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

function OrderReport({ formDate, toDate }) {
  console.log("formDate",formDate)
  const [orderData, setOrderData] = useState([0, 0, 0]); 
  const [totalOrders, setTotalOrders] = useState(0);

  // Fetch the data from the API when the component mounts
  const { storeId, saasId } = JSON.parse(localStorage.getItem('STORE_DATA'));
  useEffect(() => {
    axios
      .get(`${BASE_Url}/user-master/order_related_report_count/${saasId}/${storeId}?date=${formDate}`)
      .then((response) => {
        const { dineInCount, takeOutCount, deliveryCount, totalOrder } = response.data.data;
        setOrderData([dineInCount, takeOutCount, deliveryCount]); 
        setTotalOrders(totalOrder); 
      })
      .catch((error) => {
        console.error('Error fetching the order report:', error);
      });
  }, []);
  const data = {
    labels: ['Dine-in Orders', 'Takeout Orders', 'Delivery Orders'],
    datasets: [
      {
        label: '',
        data: orderData, 
        backgroundColor: ['violet', 'pink', 'skyblue'],
        borderColor: ['violet', 'pink', 'skyblue'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Container>
      <Box className=' ' p={2} style={{ height: "55vh", overflowY: "auto" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className='text-secondary ml-5'>
              Total Orders
            </Typography>
            <Typography variant="h5" className='ml-5'>
              {totalOrders}
            </Typography>
            <Typography variant="subtitle1" className='text-secondary ml-5'>
              Orders Sales Distribution
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="center" alignItems="center">
            <Box style={{ marginTop: "-80px" }} width="100%">
              <Pie
                data={data}
                options={{
                  plugins: {
                    legend: {
                      position: 'left', 
                      align: 'center',  
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default OrderReport;

import React, { useState } from "react";
import "./Addform.css";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Modal from 'react-bootstrap/Modal';
import { Container } from "reactstrap";
function Addform(props) {
  const data = { categoryname: "" };

  const [inputData, setInputData] = useState({
    category_name: "",
    saas_Id: "",
    status: "",
    store_id: "",
  });
  const { category_name, saas_id, status, store_id } = inputData;
  const changeHandler = (e) => {
    setInputData({
      ...inputData,
      [e.target.name]: e.target.value,
      saas_Id: "8",
      status: "enabled",
      store_id: "80001",
    });
  };
  const subHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_Url}/item/add-category`,
        inputData
      );
      console.log("API response:", response.data);
      props.addCategoryToMenu(response.data);
      props.setTrigger(false);
      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return  (


    <Modal
    show={props.trigger}
    onHide={() =>   props.setTrigger(false)}
    // fullscreen={true}
    aria-labelledby="contained-modal-title-vcenter"
    centered
    >
      <Modal.Header className="container" closeButton>
                  <Modal.Title>Add Category</Modal.Title>
                </Modal.Header>
                <Container>

        <form onSubmit={subHandler}>
          {/* <h1 className="font-bold text-2xl border-b-2 border-gray-300 mb-4 pb-5">
            Add Category *
          </h1> */}
          <label className="block text-gray-700 text-xl font-bold mb-2 mt-6">
            Category{" "}
          </label>
          <input
            className=" text-2xl border-2 border-gray-300 rounded-lg w-full font-semibold py-2 px-3 pt-4 pb-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-9"
            name="category_name"
            value={category_name}
            placeholder="Enter Category Name"
            onChange={(e) => changeHandler(e)}
          ></input>

          <div className="flex border-t-2  border-gray-300 mt-4">
            <button
              type="submit"
              className="inline-block rounded-lg bg-dark-purple px-6 py-6 pb-2 pt-2  mr-3 text-2xl font-medium  text-white mt-5 "
            >
              Done
            </button>

            <button
              type="button"
              className="inline-block rounded-lg bg-gray-300 px-6 py-6 pb-2 pt-2  mr-3 text-2xl font-medium  text-white mt-5 "
            >
              Cancel
            </button>
          </div>

          {/* <button className='close-btn'onClick={() => props.setTrigger(false)}>cl</button> */}
          {/* <RxCross2
            className="close-btn "
            onClick={() => props.setTrigger(false)}
          ></RxCross2> */}
        </form>

    
      </Container>
    </Modal>

  );
}

export default Addform;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Row, Col } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from 'react-bootstrap/Modal';
import { FaShoppingCart } from 'react-icons/fa';
// import { BsArrowLeft, BsSearch } from "react-icons/bs";
import { useSearchParams, useSearchParamstable_ } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BASE_Url } from "../../URL.js/URL";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { data } from "autoprefixer";
import { Badge } from "react-bootstrap";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, Grid, Divider, IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { addCartItem, openCart, removeCart, removeCartItem, updateCart } from "../../redux/slices/selectOrdersSlice";
import { CardFooter } from "reactstrap";

function Update() {
  const navigate = useNavigate();
  const [update, setUpdate] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [table, setTable] = useState("");
  const [payment, setpayment] = useState("");
  const [total, setTotal] = useState("");
  const [params, setParams] = useSearchParams();
  const [btnValue, setBtnValue] = useState("Dine in");
  const [value, setValue] = useState();
  const dispatch = useDispatch()
  console.log("tabel", table);
  const orderType = [
    {
      id: 1,
      button: "DineIn",
      icon: <RestaurantIcon />,
    },
    {
      id: 2,
      button: "Delivery",
      icon: <LocalShippingIcon />,
      disabled: true,
    },
    {
      id: 3,
      button: "PickUp",
      icon: <TakeoutDiningIcon />,
      disabled: true,
    },
  ];
  const [tabs] = useState(orderType);
  const { createCustomer } = useSelector((state) => state.tableData);
  // console.log("update item",update)
  const handleSave = (e) => {
    e.preventDefault();
    axios
      .post(
        `${BASE_Url}/order/update-order/${params.get("orderId")}`,

        {
          order_date: table?.order_date,
          category: table?.category,
          payment_mode: payment,
          status: table?.status,
          order_details: update,
          order_amount: total,
          table_number: data?.table_number,
          add_customer: [],
        }
      )

      .then((response) => {

        if (response.status === 200) {
          console.log("Update", response);
          dispatch(removeCart());

          Swal.fire(response.data.message);
          navigate("/")

        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  // for category list
  const [item, setItem] = useState([]);
  // for subdata
  const [selectedCategory, setSelectedCategory] = useState("dine-in");
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  // const [update, setUpdate] = useState([]);
  const [subData, setSubData] = useState("");
  const handleButtonClick = (category) => {
    setSelectedCategory(category);
  };
  const handleCartIconClick = () => {
    setCartModalOpen(true);
    console.log("clicked")
  };
  const handleCartModalClose = () => {
    setCartModalOpen(false);
  };
  useEffect(() => {
    const apiUrl = `${BASE_Url}/order/view-order-details/${params.get(
      "orderId"
    )}`;

    axios
      .get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          // console.log("update order", response);
          const data1 = response.data.data;
          const data = response.data.data.item_detail_list;
          dispatch(updateCart(data))

          console.log("naya data", data)
          setOrderDetails(data1)
          setUpdate(data);
          // setTable(data1);
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [params.get("orderId")]);

  useEffect(() => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const apiUrl = `${BASE_Url}/item/category-list/${saasId}`;
    // const apiUrl = 'http://3.111.70.84:8093/api/v1/item/view-full-menu/8';

    axios
      .get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          // console.log("item", response);

          const data = response.data.data;
          fetchData(data[0])
          console.log(" item api", data);
     
          // Assuming you want to display the data for the fourth item (index 3)
          // setItemData(data.data.item_list[3]);
          setItem(data);
        } 
        
        else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, []);

  const fetchData = async (list) => {
    console.log("asas", list);
    try {
      const apiUrl2 = `${BASE_Url}/item/view-menu/${list.category_id}/${list.saas_id}`;
      // const response = await axios.get('http://3.111.70.84:8093/api/v1/item/view-full-menu/8');
      axios.get(apiUrl2).then((response) => {
        if (response.status === 200) {
          // console.log(response, "from fetchfunction");
          const datas = response.data.data.item_list;
          // console.log(" sub data", datas);

          setSubData(datas);
          setActiveCategory(list);
        }
        
      });
      // Replace with your API endpoint
      // setSubData(response.data.data.item_list);
      // console.log(subData)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function Change(item) {

    // item.item_qty = 1;
    // item.type = selectedCategory;
    setUpdate([...update, item]);
    const existingItem = update.find(
      (existing) => existing.item_name === item.item_name
    );

    if (existingItem) {
      incres(existingItem);
    } else {
      // item.item_qty = 1;
      // item.type = selectedCategory;
      setUpdate([...update, item]);
      Swal.fire({
        title: "Item Add Seccussfull",
        icon: "success",
        timer: 1000
      })
    }
    dispatch(addCartItem(item));
    // console.log("item", item);
    // return alert("this is working");
  }

  function incres(data) {
    // console.log("ggg",data)

    const updatedItems = [...update];

    const getIndex = updatedItems.findIndex(
      (el) => el.item_id === data.item_id
    );

    if (getIndex !== -1) {
      updatedItems[getIndex] = {
        ...updatedItems[getIndex],
        item_qty: updatedItems[getIndex].item_qty + 1,
      };

      setUpdate(updatedItems);
      Swal.fire({
        title: "Item Quantity Increased Successfully",
        icon: "success",
        timer: 1000
      })
    }
  }

  // Function to decrease item quantity
  function decres(data) {
    const updatedItems = [...update];
    const getIndex = updatedItems.findIndex(
      (el) => el.item_id === data.item_id
    );

    if (getIndex !== -1) {
      if (updatedItems[getIndex].item_qty > 1) {
        updatedItems[getIndex] = {
          ...updatedItems[getIndex],
          item_qty: updatedItems[getIndex].item_qty - 1,
        };

        setUpdate(updatedItems);
      }
    }
  }
  async function delet(data) {
    const updatedItems = [...update];
    const getIndex = updatedItems.findIndex(
      (el) => el.item_id === data.item_id
    );
    if (getIndex !== -1) {
      dispatch(removeCartItem(data));
      updatedItems.splice(getIndex, 1); // Remove the item from the array
      const deletApi = `${BASE_Url}/order/delete-order/${data?.order_detail_id}/${data.item_id}`;
      try {
        await axios.delete(deletApi);
      } catch (error) {
        console.log(error);
      }

      setUpdate(updatedItems);
      //  console.log("ss",setUpdate)
    }
  }

  const finalCaluculation = (data) => {
    console.log("data aaa", data);
    const getVal = parseFloat(data?.item_price) * parseInt(data.item_qty);
    return (Math.round(getVal * 100) / 100).toFixed(2);
  };

  // **


  // Define styles based on the screen width




  const cartOpen = useSelector((state) => {
    return state.selectOrders.opencart;
  });

  const [activeCategory, setActiveCategory] = useState(null);
  const getBackgroundColor = (nameLength, isActive) => {
    const defaultColor = "bg-zinc-200 text-md font-bold p-3 border-b-2 border-zinc-500 rounded";
    const activeColor = "catgorycolorActive text-white";
    const hoverColor = "hover:bg-dark-purple hover:text-white";
    return `${defaultColor} ${isActive ? activeColor : ''} ${hoverColor}`;
  };
  const getBorderColor = (nameLength) => {
    if (nameLength < 10) {
      return "5px solid #E1D98C";
    } else if (nameLength === 10) {
      return "5px solid powderblue";
    } else {
      return "5px solid lightgreen";
    }
  };


  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };


    setScreenWidth(window.innerWidth); // Initialize screenWidth

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [activeTab, setActiveTab] = useState('Delivery');


  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

  const [search, setSearch] = useState("");
useEffect(() => {
  if (search) {
    const apiUrl = `${BASE_Url}/search/get-result/${storeId}/${saasId}/${search}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          console.log("new Api", response);
          const data = response.data.data;
          console.log(" search", data);
          // Assuming you want to display the data for the fourth item (index 3)
          // setItemData(data.data.item_list[3]);
          setSubData(data);
          // setSearch(data);
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  } else {
    // recommend();
  }
}, [search]);
  return (

    <div className="flex flex-col" >

      <Row className='m-1'>
        <Col className="mt-2" xs={12} sm={3}>
          {screenWidth >= 768 ? 
          <>
          <span style={{ fontFamily: "sans-serif" }} className=" p-2 font-semibold ">
            Order ID : {orderDetails?.order_id}
          </span>
          <span style={{ fontFamily: "sans-serif" }} className=" p-2 font-semibold ">
            Table ID : {orderDetails?.table_id}
            </span> 
          </>
          :

            ""
          } </Col>
        <Col className="mt-2" xs={12} sm={6}>
          <Paper
            component="form"
            sx={{
              height: "40px",
              p: "2px 4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: 600,
              background: "#f2f1f1",
              // border: "2px solid white",
              // borderRadius: "10px",
            }}
          // onSubmit={(e) => {
          //   getSearchEmployee(e);
          // }}
          >
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search Items"
              inputProps={{ "aria-label": "search Items" }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </Paper>
        </Col>

      </Row>
      <div >
        <Row>
          <Grid container spacing={1} >
            <Grid className="grid" item xs={12} sm={2}>


              {/* 1st component */}
              <div
                className={`  ${screenWidth >= 768 ? 'bg-zinc-200' : ''}  flex-1 m-3 fw-bold text-blue rounded category-container`}
                style={{

                  height: screenWidth >= 768 ? '550px' : 'auto',
                  overflowY: screenWidth >= 768 ? 'auto' : 'hidden',
                }}
              >
                {item?.length > 0 && (<div className="category-items"
                  style={{
                    display: 'flex',
                    flexDirection: screenWidth >= 768 ? 'column' : 'row',
                    overflowX: screenWidth >= 768 ? 'hidden' : 'auto',
                  }}>

                  {item?.map((list, index) => {
                      const isActive = activeCategory === list;
                    return (
                      <div
                        key={index}
                        className={`m-1 text-wrap cursor-pointer flex items-center justify-center ${getBackgroundColor(list?.category_name?.length, isActive)}`}
                        onClick={() => fetchData(list)}
                      >
                        {list.category_name}
                      </div>
                    );
                  })}
                </div>
                )}
              </div>
            </Grid>
            {/* 2nd component */}
            <Grid className="mt-3" item xs={12} sm={5}>
              {subData?.length > 0 ? (
                <div className="bg-white align-item-center  overflow-y-auto" style={{ height: "100vh" }}>
                  {subData && (
                    <div className="mx-4" style={{
                      alignItems: "center", height: screenWidth >= 768 ? "" : "700px", flexDirection: "row", overflowY: "scroll"
                    }}>
                      {subData.map((item) => (
                      <button
                      style={{
                        width: "fit-content",
                        height: "50px",
                        borderRadius: "1px",
                        boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.25) inset",
                        fontFamily: "DM sans-serif",
                        fontSize: "1.125rem",
                        borderLeft: getBorderColor(item.item_name?.length),
                        marginBottom: "10px", // Adjust margin for spacing
                      }}
                      className="bg-zinc-200 m-2 p-0 px-1 semibold bg-white text-gray-700 "
                          onClick={(e) => Change(item)}
                        >
                          {item.item_name}
                        </button>
                      ))}
                    </div>
                  )}
                </div>) : (
                <div className={`flex justify-center align-items-center h-100 ${screenWidth < 768 ? "mt-4" : "-mt-4"}`}>
                  <div className="">

                    <div className="d-flex justify-center align-items-center ">
                      <img src="/itemnotfound.png" alt="No Data Found" /></div>
                    <div className="d-flex justify-center align-items-center mt-4">
                      <p style={{ color: "#B2B1B1", fontFamily: "sans-serif" }} className="text-xl font-semibold">Can’t add items in the online orders</p>
                    </div>
                  </div>
                </div>
              )}

            </Grid>

            {screenWidth < 768 ? (
              <Modal
                show={cartOpen}
                onHide={() => dispatch(openCart(false))}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered

              >
                <Modal.Header className="container" closeButton>
                  <Modal.Title>Update Cart</Modal.Title>
                </Modal.Header>
                {/* Content of your cart modal */}
                <div className="bg-white "
                  style={{
                    overflowY: "auto",
                    overflow: "hidden",
                    fontFamily: "DM sans-serif",


                  }}>
                  <div className=" sticky-top">
                    <div className="bg-white flex  flex-row items-center justify-between ">
                      <Button
                        style={{ background: "#0E2954" }}
                        className="py-3 w-1/2 text-white  "
                      >
                        Dine-In
                      </Button>
                      <Button
                        style={{ background: "#CECECE" }}
                        className="py-3 text-black w-1/2  "
                        disabled >
                        Delivery
                      </Button>

                      <Button
                        style={{ background: "#CECECE" }}
                        className="py-3 w-1/2 text-black  "
                        disabled >
                        PickUp
                      </Button>

                    </div>
                  </div>

                  <div style={{ height: "300px", overflowY: "auto" }}>
                    <table className="table-auto w-full text-left whitespace-no-wrap">
                      <thead className="border"  >
                        <tr>
                          <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Item</th>
                          <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Qty</th>
                          <th scope="col" className="fw-bold py-2 pl-5 text-center">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {update.map((data, index) => (
                          <tr key={index} className="border-b-2 border-zinc-200">
                            <td className="text-center fs-5 text-xs fw-bold">{data.item_name}</td>
                            <td className="px-10">
                              <div className="flex flex-row items-center justify-center">
                                <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopLeftRadius: "10px", // Round the top-left corner
                                    borderBottomLeftRadius: "10px",
                                    // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset",
                                  }}
                                  onClick={(e) => decres(data)}
                                >
                                  -
                                </button>
                                <span className="px-2">{data.item_qty}</span>
                                <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopRightRadius: "10px", // Round the top-left corner
                                    borderBottomRightRadius: "10px",
                                  }}
                                  onClick={(e) => incres(data)}
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 fs-5 fw-bold  text-center d-flex justify-center">
                              {/* {finalCaluculation(data)} */}
                              {data.item_price}
                              <div style={{ marginLeft: "30px" }}>
                                <button type="button" onClick={() => delet(data)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  {/* <hr className="m-0" /> */}

                  <CardFooter
                    style={{
                      width: "100%",
                      // borderTop: "2px solid gray",
                      // padding: "10px",
                      // backgroundColor: "rgb(245 158 11)",
                      // height:"80px"
                    }}
                    className="
  sticky mt-10"
                  >
                    <hr className="m-0" />
                    <div className="row  p-2" >
                      <div className="col-sm sticky-bottom">
                        <div className="flex justify-around fixed bottom-10 mt-1 w-full bg-white z-10">
                          {/* <button style={{ background: "#BC362D" }} className=" justify-center border p-2 flex rounded px-4 py-1 semibold font-sans  leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none">
                            Split
                          </button> */}


                          <div className="fw-bold p-2 rounded text-center " >
                            Total &nbsp;
                            {(
                              Math.round(
                                update
                                  .map((data) => data.item_price * data.item_qty)
                                  .reduce((total, value) => total + value, 0) * 100
                              ) / 100
                            ).toFixed(2)}
                          </div>
                        </div>
                        <hr className=" mx-4 m-2" />
                      </div>

                      <div className="col-sm sticky-bottom mt-1 mb-2">
                        <div className="flex flex-row items-center justify-center ">
                          <div className="flex flex-row items-center justify-center space-x-2">
                            <div className="col-4">
                              <button
                                type="Submit"
                                className="bg-dark-purple hover:bg-yellow justify-center border p-2 flex rounded px-5 py-2 text-xs font-sans uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none"
                                onClick={handleSave}
                              >
                                Save
                              </button>
                            </div>
                            <div className="col-4">
                              <button
                                style={{
                                  cursor: selectedCategory === "Save&Print" ? "pointer" : "not-allowed",
                                }}
                                type="Submit"
                                className="bg-gray-300 hover:bg-yellow justify-center border p-2 flex rounded px-4 py-2 text-xs font-sans uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none"
                              >
                                Save&Print
                              </button>
                            </div>
                            <div className="col-4">
                              <button
                                style={{
                                  cursor: selectedCategory === "Save&Bill" ? "pointer" : "not-allowed",
                                }}
                                type="Submit"
                                className="bg-gray-300 hover:bg-yellow justify-center border p-2 flex rounded px-4 py-2 text-xs font-sans uppercase leading-normal text-white "
                              >
                                Save&Bill
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                      <hr className="" />
                    </div>
                  </CardFooter>
                </div>
              </Modal>) : (




              <Grid className="mt-3" item xs={12} sm={5}>
                {/* //modal  */}

                <div className="bg-white  rounded"
                  style={{
                    overflowY: "auto",
                    boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
                    borderRadius: "0.75rem",
                    overflow: "hidden",
                    fontFamily: "DM sans-serif",

                    border: "0 2px 12px rgba(36, 36, 39, .12)",

                  }}>



                  <div style={{ height: "300px", overflowY: "auto" }}>
                    <table className="table-auto w-full text-left  whitespace-no-wrap">
                      <thead className="border mt-1"  >
                        <tr>
                          <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Item</th>
                          <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Qty</th>
                          <th scope="col" className="fw-bold py-2 pl-5 text-center">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {update.map((data, index) => (
                          <tr key={index} className="border-b-2 border-zinc-200">
                            <td className="text-center fs-5 text-gray-600  semibold">{data.item_name}</td>
                            <td className="px-10">
                              <div className="flex flex-row items-center justify-center">
                                <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopLeftRadius: "10px", // Round the top-left corner
                                    borderBottomLeftRadius: "10px",
                                    // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset",
                                  }}
                                  onClick={(e) => decres(data)}
                                >
                                  -
                                </button>
                                <span className="px-2">{data.item_qty}</span>
                                <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopRightRadius: "10px", // Round the top-left corner
                                    borderBottomRightRadius: "10px",
                                  }}
                                  onClick={(e) => incres(data)}
                                >
                                  +
                                </button>
                              </div>
                            </td>
                            <td className="px-4 py-3 fs-5 text-gray-600 semibold ml-4 text-center d-flex justify-center">
                              {/* {finalCaluculation(data)} */}
                              {data.item_price}
                              <div style={{ marginLeft: "30px" }}>
                                <button type="button" onClick={() => delet(data)}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <hr className="m-0" />

                  <footer
                    style={{
                      width: "100%",
                      // borderTop: "2px solid gray",
                      // padding: "10px",
                      // backgroundColor: "rgb(245 158 11)",
                      // height:"80px"
                    }}
                    className="
  sticky"
                  >
                    <div className="row  " >
                      <div className="col-sm sticky-bottom">
                        <div className="flex justify-around fixed bottom-10 mt-1 w-full bg-white z-10">
                          {/* <button style={{ background: "#BC362D" }} className=" justify-center border p-2 flex rounded px-4 py-1 semibold font-sans  leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none">
                            Split
                          </button> */}


                          <div className="fw-bold p-2 rounded text-center " >
                            Total &nbsp;
                            {(
                              Math.round(
                                update
                                  .map((data) => data.item_price )
                                  .reduce((total, value) => total + value, 0) * 100
                              ) / 100
                            ).toFixed(2)}
                          </div>
                        </div>
                      </div>
                      <hr className="m-2" />
                      <div className="col-sm sticky-bottom mt-2 mb-2">
                        <div className="flex flex-row items-center justify-center ">
                          <div className="flex flex-row items-center justify-center space-x-2">

                            <div className="col-4">
                              <button
                                type="Submit"
                                className=" bg-dark-purple hover:bg-yellow justify-center border p-2 flex rounded px-5 py-2 text-xs font-sans uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none"
                                onClick={handleSave}
                              >
                                Save
                              </button>
                            </div>
                            <div className="col-4">
                              <button
                                style={{
                                  cursor: selectedCategory === "Save&Print" ? "pointer" : "not-allowed",
                                }}
                                type="Submit"
                                className="bg-gray-300 hover:bg-yellow justify-center border p-2 flex rounded px-4 py-2 text-xs font-sans uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none"
                              >
                                Save&Print
                              </button>
                            </div>
                            <div className="col-4">
                              <button
                                style={{
                                  cursor: selectedCategory === "Save&Bill" ? "pointer" : "not-allowed",
                                }}
                                type="Submit"
                                className="bg-gray-300 hover:bg-yellow justify-center border p-2 flex rounded px-4 py-2 text-xs font-sans uppercase leading-normal text-white "
                              >
                                Save&Bill
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </footer>
                </div>

              </Grid>)}
          </Grid>
        </Row>

      </div>
    </div>
  );
}

export default Update;





import React, { useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";
import { BsArrowLeft } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import DailyStore_reports from "./DailyStore_reports";
import Reports from "./Reports";
import ItemReport from "./ItemReport";
import OrderReport from "./OrderReport";
import Staffperformance_reports from "./Staffperformance_reports";
import Inventory_reports from "./Inventory_reports";
import CRM_reports from "./CRM_reports";
import Allinvoiced from "./Allinvoiced";

const Reports_names = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("total");
  const [formDate, setFormDate] = useState(new Date().toISOString().split('T')[0]);
  const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFormDateChange = (event) => {
    setFormDate(event.target.value);
  };

  const handleToDateChange = (event) => {
    setToDate(event.target.value);
  };

  const reportNames = {
    total: "All Sales Invoiced",
    daily: "Daily Sales Reports",
    order: "Order Related Reports",
    item: "Item Related Reports",
    table: "Table Performance Reports",
    category: "Category Wise Reports",
    staff: "Staff Performance Reports",
    inventory: "Inventory Reports",
    crm: "CRM Reports",
  };

  return (
    <div style={{ padding: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <h4 style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>{reportNames[selectedOption]}</h4>
        </Grid>
        {selectedOption !== "inventory" && (
          <>
            <Grid item xs={12} sm={3}>
              <Form.Group controlId="formDate">
                <Form.Control
                  type="date"
                  className="font-bold form-control"
                  style={{ width: "100%" }}
                  onChange={handleFormDateChange}
                  value={formDate}
                />
              </Form.Group>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Form.Group controlId="toDate">
                <Form.Control
                  type="date"
                  className="font-bold form-control"
                  style={{ width: "100%" }}
                  onChange={handleToDateChange}
                  value={toDate}
                />
              </Form.Group>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={4}>
          <Form.Select
            aria-label="Select a Report"
            onChange={handleSelectChange}
            value={selectedOption}
            className="form-select"
            style={{ width: "100%" }}
          >
          <option value="total"> All Sales Invoiced</option>
            <option value="daily">Daily Sales Reports</option>
            <option value="order">Order Related Reports</option>
            <option value="item">Item Related Reports</option>
            <option value="table">Table Performance Reports</option>
            {/* <option value="category">Category Wise Reports</option> */}
            <option value="staff">Staff Performance Reports</option>
            <option value="inventory">Inventory Reports</option>
            {/* <option value="crm">CRM Reports</option> */}
          </Form.Select>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              overflowX: "auto",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderRadius: "0.75rem",
              overflow: "hidden",
              fontFamily: "DM Sans, sans-serif",
              fontSize: "1.125rem",
              border: "0 2px 12px rgba(36, 36, 39, .12)",
              padding: "10px",
            }}
          >
            {selectedOption === "total" && <Allinvoiced formDate={formDate} toDate={toDate}/>}
            {selectedOption === "daily" && <DailyStore_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "table" && <Reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "item" && <ItemReport formDate={formDate} toDate={toDate} />}
            {selectedOption === "order" && <OrderReport formDate={formDate} toDate={toDate} />}
            {selectedOption === "staff" && <Staffperformance_reports formDate={formDate} toDate={toDate} />}
            {selectedOption === "inventory" && <Inventory_reports formDate={formDate} toDate={toDate} />}
            {/* {selectedOption === "crm" && <CRM_reports formDate={formDate} toDate={toDate} />} */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports_names;

import React, { useEffect, useState } from "react";
// import { BsArrowLeft, BsSearch } from "react-icons/bs";
import TabClick from "./TabClick";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaShoppingCart } from 'react-icons/fa';
import { addMenu, addOrders ,addCartItem, openCart,removeCart} from "../redux/slices/selectOrdersSlice";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";

import Checkbox from "@mui/joy/Checkbox";
import ModalCustomer from "./ModalCustomer";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { useNavigate } from "react-router-dom";
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import {
  addOrderDetails,
  addPaymentMode,
  addQuantityChange,
} from "../redux/slices/tableSlice";
import { toast } from "react-toastify";
// import pdfFile from "../assets/PDF.pdf";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { BASE_Url } from "../URL.js/URL";
import { Button, Grid, Divider, IconButton, InputBase, Paper, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CardFooter, Container } from "reactstrap";
import LinkCustomerModal from "./LoylityEnroll/LinkCustomerModal";
const SelectOrders = () => {
  const navigate = useNavigate();
  const [Note, setNote] = useState("");
  const orderType = [
    {
      id: 1,
      button: "DineIn",
      icon: <RestaurantIcon />,
    },
    {
      id: 2,
      button: "Delivery",
      icon: <LocalShippingIcon />,
      disabled: true,
    },
    {
      id: 3,
      button: "PickUp",
      icon: <TakeoutDiningIcon />,
      disabled: true,
    },
  ];
  
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [products, setProducts] = useState([]);
  const [handleShowReceipt, setHandleShowReceipt] = useState(false);
  const [optionTick, setOptionTick] = useState([]);
  const [sendCategory, setSendCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("dine-in");
  const [selectMenu, setSelectMenu] = useState([]);
  const [id, setID] = useState("");
  const [payment, setpayment] = useState("");
  const [tabs] = useState(orderType);
  const [value, setValue] = useState();
  const [viewPdf, setViewPdf] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [btnValue, setBtnValue] = useState("Dine in");
  const [customers, setCustomers] = useState([]);

  const params = useParams();
  console.log(btnValue);
  const dispatch = useDispatch();
  // for adding items,qty, & price
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [ArrayItem, setArrayItem] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [PdfFile, setPDFFile] = useState("");
  const [qr, setQr] = useState("");
  const [openCustomer, setOpenCustomer] = useState(false);
  //radio buttons
  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOptionPayment = (e) => {
    setSelectedPaymentMode(e.target.value);
    dispatch(addPaymentMode(e.target.value));
  };
  console.log(btnValue);
  // **for creating customer ***//
  // const [customer, setCustomer] = useState();
  // const [customerShow, setCustomerShow] = useState(false);
  const [AddCustomer, setAddCustomer] = useState(false)
  
  const handleCartIconClick = () => {
    setCartModalOpen(true);
    console.log("clicked")
  };

    const handleClick = () => {
    if (products.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Please add items to the cart first!',
      });
    } else {
      handleCartIconClick();
    }
  };
  const handleCartModalClose = () => {
    setCartModalOpen(false);
  };

  // **

  const {bankAccount} = JSON.parse(localStorage.getItem('STORE_DATA'))


  const [myData, setmyData] = useState([]);
  // const navigate = useNavigate();

  const [orderData, SetOrderData] = useState({
    item_id: "",
    item_name: "",
    item_qty: 1,
    item_price: "",
    status: "active",
    category: "",
    order_gross: "0",
    order_discount: "0",
    order_tax: "0",
    order_net: "0",
    order_amount: "0",
  });
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: 500,
    bgcolor: "background.paper",
   borderRadius:"10px",
    boxShadow: 24,
    p: 4,
  };
  const optionArray = [
    {
      id: 1,
      name: "Cash",
      value: "Cash",
      isActive: false,
    },
    {
      id: 2,
      name: "Card",
      value: "card",
      isActive: false,
    },
    {
      id: 3,
      name: "Due",
      value: "due",
      isActive: false,
    },
    {
      id: 4,
      name: "Other",
      value: "other",
      isActive: false,
    },
    {
      id: 5,
      name: "Part",
      value: "part",
      isActive: false,
    },
  ];

  // console.log(handleTenderAmount());
  const cartOpen = useSelector((state) => {
    return state.selectOrders.opencart;
  });

  const data = useSelector((state) => {
    return state.selectOrders.data;
  });
  // console.log(orderData);
  // for menu List
  const menuLists = useSelector((state) => {
    return state.selectOrders.menuList;
  });
  // **for createlist getting api
  const createCustomers = useSelector((state) => {
    return state.tableData.createCustomer;
  });

  const getCatogery = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .get(`${BASE_Url}/item/category-list/${saasId}`)
      .then((res) => {
        setCategory(res.data.data);
        setID(res.data?.data[0]?.category_id)
        setActiveCategory(res.data?.data[0]?.category_id)
      })
      .catch((err) => console.log(err));
  };
 
  useEffect(() => {
    getCatogery();
  }, []);

  const getMenu = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    axios
      .get(`${BASE_Url}/item/view-menu/${id}/${saasId}`)
      .then((res) => {
        console.log("MENU", res.data.data.item_list);
        setSelectMenu(res.data.data.item_list);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if(id){
      getMenu();
    }
  }, [id]);

  // const getBill = () => {
  //   axios
  //     .post(`${BASE_Url}/order/view-order-details/${params.order_id}`)
  //     .then((res) => {
  //       console.log("RES ORDER ID", res);
  //     });
  // };

  // useEffect(() => {
  //   getBill();
  // }, [params.order_id]);

  const handleQtyChange = (index, newQty) => {
    const updatedItems = [...ArrayItem];
    updatedItems[index].qty = newQty;
    setArrayItem(updatedItems);
    dispatch(addQuantityChange({ index: index, newQty: newQty }));
  };
  // const handleTotal = (e) => {
  //   // const Total = ArrayItem*price
  // };
  const calculateTotalPrice = () => {
    let totalPrice = 0;

    ArrayItem.forEach((item) => {
      totalPrice += item.qty * item.price;
    });

    return totalPrice;
  };

  console.log(ArrayItem);
  //   menuList
  //
  // for setting data into price,items & qty
  const addArray = (name, price) => {
    const newObject = { name: name, qty: 1, price: price };
    setArrayItem((prevArray) => [...prevArray, newObject]);
  };

  const handleArraySelect = (e) => {
    addArray(e.descirption, e.price);

    dispatch(
      addOrderDetails({
        ...orderData,
        item_id: e.item_id,
        item_name: e.descirption,
        item_price: e.price,
      })
    );
  };

  const handleAddProducts = (el) => {
    dispatch(addCartItem(el))
    const existingItem = products.find(
      (existing) => existing.item_id === el.item_id
    );

    if (existingItem) {
      incres(existingItem);
      Swal.fire({
        title: 'Item Quantity Update Successful',
        icon: 'success',
        timer: 1000,
      });
    } else {
      // el.item_qty = 1;
      setProducts([...products, el]);
  
      Swal.fire({
        title: 'Item Add Successful',
        icon: 'success',
        timer: 1000,
      });
    }
  };

  const incres = (item) => {
    const updatedItems = [...products];

    const getIndex = updatedItems.findIndex((el) => el.item_id === item.item_id);

    if (getIndex !== -1) {
      updatedItems[getIndex] = {
        ...updatedItems[getIndex],
        item_qty: updatedItems[getIndex].item_qty + 1,
      };

      setProducts(updatedItems);
    
    }
  };

  const calculation = (item) => item.item_price * item.item_qty;

  // // Function to decrease item quantity
  function decres(data) {
    const updatedItems = [...products];
    const getIndex = updatedItems.findIndex(
      (el) => el.item_id === data.item_id
    );

    if (getIndex !== -1) {
      if (updatedItems[getIndex].item_qty > 1) {
        updatedItems[getIndex] = {
          ...updatedItems[getIndex],
          item_qty: updatedItems[getIndex].item_qty - 1,
        };
        setProducts(updatedItems);
      }
    }
  }

  // ---------------------------------
  const handleTenderAmount = () => {
    if (optionTick?.length > 0) {
      const obj = {};
      optionTick.map((item) => {
        obj[item.option] = item.amount;
      });
      return obj;
      // setSendValues(obj)
    }
    return {};
  };

  const handleSaveTransaction = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const formatDateTime = (date) => {
      return `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate()
        .toString()
        .padStart(2, '0')} ${date.getHours()
        .toString()
        .padStart(2, '0')}:${date.getMinutes()
        .toString()
        .padStart(2, '0')}:${date.getSeconds()
        .toString()
        .padStart(2, '0')}`;
    };
    
    const currentDateTime = formatDateTime(new Date());
    const { registerId } = JSON.parse(localStorage.getItem("USER_DATA"));
    axios
      .post(`${BASE_Url}/transaction/nano-invoice`, {
        register_id: registerId,
        store_id: storeId,
        saas_id: saasId,
        customer_id: 12,
        table_id: params.id,
        // mobile_number: "1275567890",
        cashier_id: saasId,
        trans_start_time: currentDateTime,
        trans_end_time: currentDateTime,
        tender: handleTenderAmount(),
        cart_items: products,
      })
      .then((res) => {
        if (res.status === 200) {
          // toast.success(res.data.message);
          // toast.success("SUCCESS");
          setHandleShowReceipt((state) => !state);
          setPDFFile(res.data?.data?.pdf_file_name);
          setQr(res.data?.data?.qr_file_name);
          setViewPdf(true);
        }
      });
    // axios.post(`${BASE_Url}/order/create`, {
    //   order_date: "2023-08-22",
    //   table_name: params.name,
    //   saas_id: saasId,
    //   store_id: storeId,

    //   category: sendCategory,
    //   payment_mode: Object.keys(handleTenderAmount())[0],
    //   paid_or_loyalty: "paid",
    //   status: "pending",
    //   order_details: products,
    //   add_customer: [],
    // });
  };

  const handleButtonClick = (category) => {
    setSelectedCategory(category);
  };

  const handleDelete = (id) => {
    // console.log(id);
    const updatedItems = products.filter((el) => el.item_id !== id);
    setProducts(updatedItems);
  };

  const handleBookTable = () => {
    // console.log("TENDER VALUE", payment.length);
    const currentDate = new Date().toISOString().split('T')[0];
    axios
      .post(`${BASE_Url}/order/create`, {
        order_date: currentDate,
        table_name: params.name,
        table_id: params.id,
        saas_id: saasId,
        store_id: storeId,
        category: sendCategory,
        payment_mode: payment,
        paid_or_loyalty: "paid",
        status: "pending",
        order_type: btnValue,
        order_details: products,
        add_customer: customers,
        approval_status:"pending",
        note:Note
      })
      .then((res) => {
        dispatch(removeCart())
        console.log("SAVE CREATE ORDER", res);
        if (res.status === 200) {
          Swal.fire(res.data.message);
          navigate("/");
          setProducts([]);
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = { customer_name: name, mobile_number: mobile, address };
    // console.log("khh",data);
    setCustomers((state) => [...state, data]);
    setName("");
    setAddress("");
    setMobile("");
    setOpenCustomer((state) => !state);
    Swal.fire("Customer Added...");
  };

  console.log("CUSTOMERS", customers);
  const getBorderColor = (nameLength) => {
    if (nameLength < 10) {
      return "5px solid #E1D98C";
    } else if (nameLength === 10) {
      return "5px solid powderblue";
    } else {
      return "5px solid lightgreen";
    }
  };

  
  const getBackgroundColor = (isActive) => {
    const defaultColor = "bg-zinc-200 text-md font-bold p-3 border-b-2 border-zinc-500 rounded";
    const activeColor = "catgorycolorActive  text-white";
    const hoverColor = "hover:bg-dark-purple hover:text-white";
    return `${defaultColor} ${isActive ? activeColor : ''} ${hoverColor}`;
  };


const handleMobileChange = (e) => {
  const inputValue = e.target.value;
  const onlyDigits = inputValue.replace(/\D/g, ""); // Remove non-digit characters

  if (onlyDigits.length <= 10) {
    setMobile(onlyDigits);
  }
};
const [screenWidth, setScreenWidth] = useState(0);

useEffect(() => {
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  setScreenWidth(window.innerWidth); // Initialize screenWidth

  window.addEventListener("resize", handleResize);

  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);


const [search, setSearch] = useState("");
useEffect(() => {
  if (search) {
    const apiUrl = `${BASE_Url}/search/get-result/${storeId}/${saasId}/${search}`;
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          console.log("new Api", response);
          const data = response.data.data;
          console.log(" search", data);
          // Assuming you want to display the data for the fourth item (index 3)
          // setItemData(data.data.item_list[3]);
          setSelectMenu(data);
          // setSearch(data);
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  } else {
    // recommend();
  }
}, [search]);
  return (
    <>
    <div className="flex flex-col ">
     <Row className='m-1'>

     <Col className="mt-2" xs={12} sm={3}>
     {screenWidth >= 768 ? <button style={{background:"#E1D98C"}} className=" p-2 px-5  rounded">
          Table {params.name}
        </button>
        :""
} </Col>
<Col className="mt-2" xs={12} sm={6}>
          <Paper
            component="form"
            sx={{
              height: "40px",
              p: "2px 4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: 600,
              background: "#f2f1f1",
              // border: "2px solid white",
              // borderRadius: "10px",
            }}
          // onSubmit={(e) => {
          //   getSearchEmployee(e);
          // }}
          >
            <IconButton
              // type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search Items"
              inputProps={{ "aria-label": "search Items" }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </Paper>
        </Col>
      </Row>
      <Row>
   
      <Grid container spacing={1}>
      <Grid className="grid" item xs={12} sm={2}>
      {/* 1st component */}
      <div
        className={`  ${screenWidth >= 768 ? 'bg-zinc-200' : ''}  flex-1 m-3 fw-bold text-blue rounded category-container`}
        style={{
          height: screenWidth >= 768 ? '550px' : 'auto',
          overflowY: screenWidth >= 768 ? 'auto' : 'hidden',
        }}
      >
        {category.length > 0 && (
          <div
            className="category-items"
            style={{
              display: 'flex',
              flexDirection: screenWidth >= 768 ? 'column' : 'row',
              overflowX: screenWidth >= 768 ? 'hidden' : 'auto',
            }}
          >
            {category.map((list, index) => {
            // const isActive = selectedCategoryId === list.category_id;
              return (
                <div
                  key={index}
                  className={`flex text-wrap items-center justify-center ${getBackgroundColor(activeCategory === list.category_id)}`}
                            onClick={() => {
                              setActiveCategory(list.category_id);
                    // setSelectMenu(list.category_id);
                    setSendCategory(list.category_name);
                    setID(list.category_id);
                 
                  }}
                  style={{
                    minWidth: screenWidth < 768 ? '150px' : 'auto',
                    padding: '10px',
                    margin: '5px',
                    cursor: 'pointer',
                  }}
                >
                  {list.category_name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Grid>


        {/* 2nd component */}
        <Grid className="mt-3" item xs={12} sm={5}  style={{ height:screenWidth >= 768 ?"62vh": "55vh", overflowY: "auto", overflowX:"hidden" }}>
        <div className="bg-white align-item-center  ">
    {selectMenu?.length > 0 ? (
      <div className="mx-10" style={{    flexWrap: "wrap", flexDirection: "row",  }}>
        {selectMenu&&selectMenu.map((el, index) => (
          <button
            key={index}
            style={{
              width: "fit-content",
              height: "50px",
              borderRadius: "1px",
              boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.25) inset",
              fontFamily: "DM sans-serif",
              fontSize: "1rem",
              borderLeft: getBorderColor(el.item_name?.length),
              marginBottom: "10px", // Adjust margin for spacing
            }}
            className="bg-zinc-200 m-2 p-0 px-1 fw-bold bg-white text-gray-700 "
            onClick={(e) => handleAddProducts(el)}
          >
            {el.item_name}
          </button>
        ))}
      </div>
    ) : (
      <div className={`flex justify-center align-items-center h-50 mt-5 }`}>
        <div className="">

          <div className="d-flex justify-center align-items-center ">
            <img src="/itemnotfound.png" alt="No Data Found" /></div>
          <div className="d-flex justify-center align-items-center mt-4">
            <p style={{ color: "#B2B1B1", fontFamily: "sans-serif" }} className="text-xl font-semibold">Can’t items Found</p>
          </div>
        </div>
      </div>
    )}
  </div>
  </Grid>
    
        {screenWidth < 768 ? (
        <Modal
        show={cartOpen}
        onHide={() => dispatch(openCart(false))}
        fullscreen={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >
             <Modal.Header className="container" closeButton>
                  <Modal.Title>Cart</Modal.Title>
                </Modal.Header>
            <div className="bg-white  p-2 rounded-3" 
    >
            {/* <TabClick />   */}

            <div style={{  overflowY: "auto" }}>
            <div className=" sticky-top">
                    <div className="bg-white flex  flex-row items-center justify-between ">
                      <Button
                        style={{ background: "#0E2954" }}
                        className="py-3 w-1/2 text-white  "
                      >
                        Dine-In
                      </Button>
                      <Button
                        style={{ background: "#CECECE" }}
                        className="py-3 text-black w-1/2  "
                        disabled >
                        Delivery
                      </Button>

                      <Button
                        style={{ background: "#CECECE" }}
                        className="py-3 w-1/2 text-black  "
                        disabled >
                        PickUp
                      </Button>

                    </div>
                  </div>
                  <div style={{ height: "300px", overflowY: "auto" }}>
                    <table className="table-auto w-full text-left whitespace-no-wrap">
                      <thead className="border"  >
    <tr>
      <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Item</th>
      <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Qty</th>
      <th scope="col" className="fw-bold py-2 pl-5 text-center">Price</th>
    </tr>
  </thead>
                <tbody>
                  {products.length > 0 &&
                    products.map((el) => (
                      <tr className="border-b-2 border-zinc-200">
                
                        <td className=" text-center fs-6 fw-bold">{el.item_name}</td>

                        <td className="px-10">
                          <div className="flex flex-row items-center justify-center">
                          <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopLeftRadius: "10px", // Round the top-left corner
                                    borderBottomLeftRadius: "10px",
                                    // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset",
                                  }}  onClick={() => decres(el)}
                              // onClick={() => incres(el)}
                            >
                              -
                            </button>
                          <span className="px-2">{el.item_qty}</span>  
                          <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopRightRadius: "10px", // Round the top-left corner
                                    borderBottomRightRadius: "10px",
                                  }} onClick={() => incres(el)}
                            >
                              +
                            </button>
                          </div>
                        </td>

                        <td className="px-4 py-3 fs-5 fw-bold  text-center d-flex justify-center">{bankAccount} {calculation(el)}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
              </div>

          
              <CardFooter
                    style={{
                      width: "100%",
                      // borderTop: "2px solid gray",
                      // padding: "10px",
                      // backgroundColor: "rgb(245 158 11)",
                      // height:"80px"
                    }}
                    className="
  sticky mt-10"
                  >
                    <hr className="m-0" />
                    <div className="row  " >
                      <div className="col-sm sticky-bottom">
                        <div className="flex justify-around fixed bottom-10 mt-1 w-full bg-white z-10">
  {/* <button className="bg-red-600 h-100 justify-center border p-2 flex rounded px-4  text-xs font-sans uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none">
    Split
  </button> */}


     <button
       className="btn  text-nowrap "
       style={{fontFamily: "Inter",
       fontStyle: "normal",
       fontWeight: "700",
       lineHeight: "normal" }}
       onClick={() => setAddCustomer((state) => !state)}
     >
       <PersonAddIcon /> 
       <span> Add Customer </span>
     </button>
 

     <div className="fw-bold text-nowrap mt-2 text-center " >
    {/* <AttachMoneyIcon style={{ fontSize: 24, marginRight: 4 }} /> */}
    <span className="font-bold">Total:</span> 
    &#x20B9;{(
      Math.round(
        products
          .map((data) => data.item_price * data.item_qty)
          .reduce((total, value) => total + value, 0) * 100
      ) / 100
    ).toFixed(2)}
  </div>
</div>
  <hr className=" mx-4 m-2" />
                 
                 
                  <div className="col-sm sticky-bottom">
                    <div className="flex flex-row items-center justify-center mt-4">
                      {products && products.length > 0 ? (
                        <div className="flex flex-row items-center justify-center space-x-8">
                          <button
                            type="button"
                            className="bg-dark-purple hover:bg-yellow justify-center border p-2 flex rounded px-5 py-2 text-xs font-sans uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none"
                            style={{background:"rgb(14, 41, 84)",color:"white"}}
                            onClick={() => {
                              handleBookTable();
                              // play()
                            }}
                          >
                            Save
                          </button>
                          <audio id="myAudio">
                            <source src="public/music.mp3" type="audio/mp3" />
                            Your browser does not support the audio tag.
                          </audio>

                          <button type="button"  className="btn btn-rgb(14, 41, 84) disabled"
                            style={{background:"rgb(14, 41, 84)",color:"white"}}>
                            Print
                          </button>

                          <button type="button"  className="btn btn-rgb(14, 41, 84) disabled"
                            style={{background:"rgb(14, 41, 84)",color:"white"}}>
                            Email
                          </button>
                          
                        </div>

                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

              </div></CardFooter>

              {/* <div className="pt-5 pb-5 pl-3 border-b-2 border-t-2 flex flex-row items-center justify-center">
              <div className="mx-2">
                <input
                  type="radio"
                  onChange={() => setpayment("Cash")}
                  name="paymentmode"
                  value="Cash"
                  id="Cash"
                  className="text-xl font-semibold mr-1"
                />
                <label className="text-xl font-semibold" htmlFor="Cash">
                  Cash
                </label>
              </div>
              <div className="mx-2">
                <input
                  type="radio"
                  onChange={() => setpayment("Card")}
                  name="paymentmode"
                  value="Card"
                  id="Card"
                  className="text-xl font-semibold mr-1"
                />
                <label htmlFor="Card" className="text-xl font-semibold">
                  Card
                </label>
              </div>
              <div className="mx-2">
                <input
                  type="radio"
                  onChange={() => setpayment("Due")}
                  name="paymentmode"
                  value="Due"
                  id="Due"
                  className="text-xl font-semibold mr-1"
                />
                <label htmlFor="Due" className="text-xl font-semibold">
                  Due
                </label>
              </div>
              <div className="mx-2">
                <input
                  type="radio"
                  onChange={() => setpayment("Other")}
                  name="paymentmode"
                  value="Other"
                  id="Other"
                  className="text-xl font-semibold mr-1"
                />
                <label htmlFor="Other" className="text-xl font-semibold ">
                  Other
                </label>
              </div>
              <div className="mx-2">
                <input
                  type="radio"
                  onChange={() => setpayment("Part")}
                  name="paymentmode"
                  value="Part"
                  id="Part"
                  className="text-xl font-semibold mr-1"
                />
                <label htmlFor="Part" className="text-xl font-semibold">
                  Part
                </label>
              </div>
            </div> */}
              <div>
                {/* <div>
                <Box
                  className="mt-4 mx-8"
                  sx={{ display: "flex", alignItems: "center", gap: 3 }}
                >
                  <Checkbox label="Paid" size="md" name="loyality" />
                  <Checkbox label="Loyality" size="md" name="loyality" />
                </Box>
              </div> */}
              </div>

            </div>

            {/* --PDF MODAL-- */}

          
            {/* Add Customer */}
          </div>
        </Modal>) : (


<Grid className="mt-3" item xs={12} sm={5}>
             <div className="bg-white  rounded" 
        style={{
          overflowY: "auto",
          boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
          borderRadius: "0.75rem",
          overflow: "hidden",
          fontFamily: "DM sans-serif",
       
          border: "0 2px 12px rgba(36, 36, 39, .12)",
         
        }}>
             {/* <TabClick />   */}
 
             <div
               className=""
               style={{ height: "70vh",  }}
             >
            
             <div style={{ height: "300px", overflowY: "auto" }}>
             {products.length > 0 ? (    <table className="table-auto w-full text-left  whitespace-no-wrap">
                      <thead className="border "  >
                        <tr>
                          <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Item</th>
                          <th scope="col" className="fw-bold px-4 py-2 pl-5 pr-5 text-center">Qty</th>
                          <th scope="col" className="fw-bold py-2 pl-5 text-center">Price</th>
                        </tr>
                      </thead>
                 <tbody>
                   {products.length > 0 &&
                     products.map((el) => (
                       <tr className="border-b-2 border-zinc-200">
                 
                         <td className=" text-center fs-5 fw-bold">{el.item_name}</td>
 
                         <td className="px-10">
                           <div className="flex flex-row items-center justify-center">
                           <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopLeftRadius: "10px", // Round the top-left corner
                                    borderBottomLeftRadius: "10px",
                                    // boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25) inset",
                                  }}
                               onClick={() => decres(el)}
                               // onClick={() => incres(el)}
                             >
                               -
                             </button>
                           <span className="px-2">{el.item_qty}</span>  
                           <button
                                  className=" border-2 fs-5 fw-bold text-center"
                                  style={{
                                    width: "30px",
                                    height: "30px",

                                    background: "white",
                                    color: "black",
                                    flexShrink: 0,
                                    borderTopRightRadius: "10px", // Round the top-left corner
                                    borderBottomRightRadius: "10px",
                                  }}
                               onClick={() => incres(el)}
                             >
                               +
                             </button>
                           </div>
                         </td>
 
                         <td className="px-4 py-3 fs-5 fw-bold ml-4 text-center">{bankAccount} {calculation(el)}</td>
                       </tr>
                     ))}
                 </tbody>
               </table>)
               :( <div className={`flex justify-center align-items-center h-100 ${screenWidth < 768 ? "mt-4" : "-mt-4"}`}>
               <div className="">
       
                 <div className="d-flex justify-center align-items-center ">
                   <img src="/EmptyCart.svg" width="50px" alt="No Data Found" /></div>
                 <div className="d-flex justify-center align-items-center mt-4">
                   <p style={{ color: "#B2B1B1", fontFamily: "sans-serif" }} className="text-xl font-semibold">   Cart Is Empty Add Items</p>
                 </div>
               </div>
             </div>)}
               </div>
 
    
             <hr className="m-0" />
             {products.length > 0 &&  <footer
                    style={{
                      width: "100%",
                      // borderTop: "2px solid gray",
                      // padding: "10px",
                      // backgroundColor: "rgb(245 158 11)",
                      // height:"80px"
                    }}
                    className="
  sticky"
                  >
                    <div className="row mx-2 w-1/3" >
                    <TextField
                      type="text"
                      value={Note}
                      placeholder="Add Note"
                      onChange={(e) => setNote(e.target.value)}
                      className="form-control"
                      aria-describedby="costHelp"
                    />
                    </div>
                    <div className="row  " >
                      <div className="col-sm sticky-bottom">
                        <div className="flex justify-around fixed bottom-10 mt-1 w-full bg-white z-10">
   {/* <button style={{ fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                        background: "#BC362D"  }} className=" justify-center border p-2 flex rounded px-4 py-2 text-xs font-sans uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none">
     Split
   </button> */}
    
     <button
       className="btn  rounded"
       style={{marginRight: "20px",fontFamily: "Inter",
       fontStyle: "normal",
       fontWeight: "700",
       lineHeight: "normal" }}
       onClick={() => setAddCustomer((state) => !state)}
     >
       <PersonAddIcon /> 
       <span> Add Customer </span>
     </button>
 
   <div className="flex items-center text-lg font-bold">
     {/* <AttachMoneyIcon style={{ fontSize: 24, marginRight: 4 }} /> */}
     <span className="font-bold">Total:</span> &nbsp;
     {bankAccount} {(
       Math.round(
         products
           .map((data) => data.item_price * data.item_qty)
           .reduce((total, value) => total + value, 0) * 100
       ) / 100
     ).toFixed(2)}
   </div>
 </div>
                 
 <hr className="m-2" />          
                 
 <div className="col-sm sticky-bottom mt-2 mb-2">
                        <div className="flex flex-row items-center justify-center ">
                       {products && products.length > 0 ? (
                        <div className="flex flex-row items-center justify-center space-x-2">
                          
                           <button
                             type="button"
                             className="btn btn-rgb(14, 41, 84)"
                             style={{background:"rgb(14, 41, 84)",color:"white", fontFamily: "Inter",
                             fontStyle: "normal",
                             fontWeight: "700",
                             lineHeight: "normal"}}
                             onClick={() => {
                               handleBookTable();
                               // play()
                             }}
                           >
                             Save
                           </button>
                           <audio id="myAudio">
                             <source src="public/music.mp3" type="audio/mp3" />
                             Your browser does not support the audio tag.
                           </audio>
 
                           <button type="button"  className="btn btn-rgb(14, 41, 84) disabled"
                             style={{background:"rgb(14, 41, 84)",color:"white", fontFamily: "Inter",
                             fontStyle: "normal",
                             fontWeight: "700",
                             lineHeight: "normal"}}>
                             Print
                           </button>
 
                           <button type="button"  className="btn btn-rgb(14, 41, 84) disabled"
                             style={{background:"rgb(14, 41, 84)",color:"white", fontFamily: "Inter",
                             fontStyle: "normal",
                             fontWeight: "700",
                             lineHeight: "normal"}}>
                             Email
                           </button>
                           
                         </div>
 
                       ) : (
                         ""
                       )}
                     </div>
                   </div>
                 </div>
 
               </div></footer>}
 
             
 
             </div>
 
             {/* --PDF MODAL-- */}
 
             {/* Add Customer */}
           </div>
           </Grid>)}
           </Grid> 
</Row>
      </div>
      <Modal
              open={viewPdf}
              // onClose={}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                },
                content: {
                  width: "fit-content",
                  margin: "auto",
                  transform: "translateY(15%)",
                  background:"white"

                },
              }}
            >
              <Box sx={style}>
                <div
                  style={{

                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginBottom: "20px",
                    cursor: "pointer",
                  }}
                  onClick={() => setViewPdf((state) => !state)}
                >
                  <AiOutlineClose size={20} />
                </div>
                {PdfFile && (
                  <div style={{ height: "400px" }}>
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                      <Viewer
                        fileUrl={`${BASE_Url}/transaction/pdf/${PdfFile}`}
                        plugins={[defaultLayoutPluginInstance]}
                      />
                    </Worker>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {
                    <div style={{ height: 200, width: 200 }}>
                      {qr && (
                        <img
                          style={{ height: "100%", width: "100%" }}
                          src={`${BASE_Url}/transaction/pdf-qr/${qr}`}
                          alt="qr code"
                        />
                      )}
                    </div>
                  }
                </div>
              </Box>
            </Modal>
      
            <LinkCustomerModal open={AddCustomer} setAddCustomer={setAddCustomer}  />
</>
  );
};

export default SelectOrders;

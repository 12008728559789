import React, { useState } from "react";
import { Link } from "react-router-dom";
// import Select from "react-select";
import { Grid, TextField, Button, InputLabel, Select, MenuItem, FormControl, } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
const MemberEnrollModal = (props) => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const [currentStep, setCurrentStep] = useState(1);

  const [dob, setDob] = useState("");
  const [clientId, setClientId] = useState("");
  const [baseCurrecy, setBaseCurrency] = useState("");
  const [mobile, setMobile] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [email, setEmail] = useState("");
  const [nationality, setNationality] = useState("");
  const [language, setLanguage] = useState("");
  const [sourceChannel, setSourceChannel] = useState("");
  const [pinCode, setPincode] = useState("");
  const [preferredAddress, setPreferredAddress] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [gender, setGender] = useState("");
  const [businessCreatedDate, setBusinessCreatedDate] = useState("");
  const [anniversaryDate, setAnniversaryDate] = useState("");
  const [selectedMaritalOption, setSelectedMaritalOption] = useState(null);
  const [selectedCountryOption, setSelectedCountryOption] = useState(null);
  const [selectedLocationOption, setselectedLocationOption] = useState(null);
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [selectedSuffixOption, setSelectedSuffixOption] = useState(null);

  const optionsForCity = [{ value: "delhi", label: "Delhi" }];
  const optionsForCountry = [{ value: "india", label: "India" }];
  const optionsforSuffix = [
    { value: "Mr.", label: "Mr." },
    { value: "Mrs.", label: "Mrs." },
  ];
  const optionsforSourceOption = [{ value: "WEB", label: "WEB" }];
  const optionsforMaritalStatusOption = [
    { value: "single", label: "Single" },
    { value: "married", label: "Married" },
  ];
  const optionsforLocationOption = [
    { value: "south-delhi", label: "South Delhi" },
  ];

  const onOptionChange = (e) => {
    setGender(e.target.value);
  };

  const handleSelectedSuffix = (selectedOption) => {
    setSelectedSuffixOption(selectedOption.value);
  };

  const handleMaritalOption = (selectedOption) => {
    setSelectedMaritalOption(selectedOption.value);
  };

  const handleLocationOption = (selectedOption) => {
    setselectedLocationOption(selectedOption.value);
  };

  const handleCityOption = (selectedOption) => {
    setSelectedCityOption(selectedOption.value);
  };

  const handleCountryOption = (selectedOption) => {
    setSelectedCountryOption(selectedOption.value);
  };

  const handleNextStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = (e) => {
    e.preventDefault();
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://103.148.165.246:8094/test/v1/loyalty/customer',{
        customer_id: "",
        client_id: saasId,
        base_currency: "INR",
        mobile_number: mobile,
        customer_name: customerName,
        email_id: email,
        source_channel: "POS",
        business_created_date: businessCreatedDate,
        nationality: nationality,
        language: language,
        dob: dob,
        gender: gender,
        suffix: selectedSuffixOption,
        marital_status: selectedMaritalOption,
        anniversary_date: anniversaryDate,
        preferred_address: preferredAddress,
        location: selectedLocationOption,
        address_line1: address1,
        address_line2: address2,
        city: selectedCityOption,
        country: selectedCountryOption,
        pincode: pinCode,
      });

      
        props.onHide()
        Swal.fire({
            title: "Member Enroll Successfully",
            icon: "success",
          });

      
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };



  const handleClosemodal = () => {
    props.onHide()
    setCurrentStep(1)
  
  };
  return (


    <Modal
    {...props}
    // fullscreen={true}
    onHide={handleClosemodal}
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
          <Modal.Header className="container" closeButton>
      <Modal.Title> MemberEnroll</Modal.Title>
    </Modal.Header>
                  <Modal.Body>
 
                  <Container
    fluid
    className=" container fw-bold"
    style={{
      background: "white",
      maxWidth: "1000px",
      flexWrap: "wrap",
      height: "fit-content",
      overflowY: "auto",
      boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
      borderRadius: "0.75rem",
      overflow: "hidden",
      fontSize: "1rem",
      border: "0 2px 12px rgba(36, 36, 39, .12)",
      padding: "30px",
    }}
  >

{/* <Row className="mb-5">
      <Col className="d-flex justify-content-center">
    
        <h4
          style={{ fontSize: "30px", fontWeight: "600", 
 
         }}
        >
         Member Enroll Form
        </h4>
      </Col>
    </Row> */}

    <Grid container spacing={2}>

              {currentStep === 1 && (
                <>
                     <Grid item xs={12} md={6}>
                  <TextField
                 
                    type="number"
                    className="form-control "
                    label="Mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  /></Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                 
                    type="text"
                    className="form-control "
                    label="Customer Name"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                  /></Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                 
                    type="email"
                    className="form-control "
                    label="Email Id"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  /></Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                 
                    type="text"
                    className="form-control "
                    label="Nationality"
                    value={nationality}
                    onChange={(e) => setNationality(e.target.value)}
                  /></Grid>
                  <Grid item xs={12} md={6}>
                  <TextField
                  
                    type="text"
                    className="form-control "
                    label="Language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  /> </Grid>
                  <Grid item xs={12} md={6}>
                          <TextField
                fullWidth
                label="Dob"
                name="dob"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              /></Grid>
                 
              <Grid item xs={12} md={6}>
             
                    <FormControl fullWidth>
                      <InputLabel>Gender</InputLabel>
                      <Select
                        fullWidth
                        value={gender}
                        label="Gender"
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                      </Select>
                    
              </FormControl>
            </Grid>
                                      <Grid item xs={12}>
                                      <Button
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNextStep}
                                      >
                                        Next
                                      </Button>
                                    </Grid>
                                    </>
             
              )}

              {currentStep === 2 && (
                <>
                 <Grid container spacing={2}>
               
                  <Grid item xs={12} md={6}>
                          <TextField
                fullWidth
                label="anniversaryDate"
                name="anniversaryDate"
                type="date"
                value={anniversaryDate}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setAnniversaryDate(e.target.value)}
              /></Grid>
              

<Grid item xs={12} md={6}>
                  <TextField
                     fullWidth
                    type="text"
                    label="Preferred Address"
                    value={preferredAddress}
                    onChange={(e) => setPreferredAddress(e.target.value)}
                  /></Grid>
                        <Grid item xs={12} md={6}>
                  <TextField
                    type="text"
                    className="form-control "
                    label="Address 1"
                    value={address1}
                    onChange={(e) => setAddress1(e.target.value)}
                  /></Grid>
                        <Grid item xs={12} md={6}>
                  <TextField
                 
                    type="text"
                    className="form-control "
                    label="Address 2"
                    value={address2}
                    onChange={(e) => setAddress2(e.target.value)}
                  /></Grid>
                        <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="Suffix-label">Suffix</InputLabel>
                          <Select
                           id="Suffix-label"
                           label="Suffix"
                            value={selectedSuffixOption}
                            onChange={(e) => setSelectedSuffixOption(e.target.value)}
                          >
                            {optionsforSuffix.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel   id="Marital-Status">Marital Status</InputLabel>
                          <Select
                              id="Marital-Status"
                              label="Suffix"
                            value={selectedMaritalOption}
                            onChange={(e) => setSelectedMaritalOption(e.target.value)}
                          >
                            {optionsforMaritalStatusOption.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
             
            <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel   id="Location">Location</InputLabel>
                          <Select
                              id="Location"
                              label="Location"
                            value={selectedLocationOption}
                            onChange={(e) => setselectedLocationOption(e.target.value)}
                          >
                            {optionsforLocationOption.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel   id="City">City</InputLabel>
                          <Select
                              id="City"
                              label="City"
                            value={selectedCityOption}
                            onChange={(e) => setSelectedCityOption(e.target.value)}
                          >
                            {optionsForCity.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

               <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel   id="Country">Country</InputLabel>
                          <Select
                           id="Country"
                           label="Country"
                            value={selectedCountryOption}
                            onChange={(e) => setSelectedCountryOption(e.target.value)}
                          >
                            {optionsForCountry.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
              <Grid item xs={12} md={6}>
                  <TextField
                 
                    type="number"
                    className="form-control "
                    label="Pin Code"
                    value={pinCode}
                    onChange={(e) => setPincode(e.target.value)}
                  /></Grid>
                      
              
                </Grid>
                <Grid item xs={12} md={12} className="mt-3 d-flex justify-content-center">
                            <Button
                            color="primary" 
                            className="mx-2 text-white mb-3 fw-bold"
                            style={{ backgroundColor: "#4584e2" }}
                              onClick={handlePreviousStep}
                            >
                              Previous
                            </Button>
                       
                            <Button
                             className=" text-white mb-3 fw-bold"
                             style={{ backgroundColor: "#4584e2" }}
                           color="primary" type="submit"
                              onClick={handleSubmit}
                            >
                              Submit
                            </Button>
                       
                       
                            </Grid>
                </>
              )}
            </Grid>
    </Container>


    </Modal.Body>
    </Modal>
  );
};

export default MemberEnrollModal;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orange {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: orange;
}
.grey {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(117, 116, 114);
}
.yellow {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #d97706;
}
.green {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #65a30d;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Tables.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,wBAAwB;AAC1B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B","sourcesContent":[".orange {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background-color: orange;\n}\n.grey {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background-color: rgb(117, 116, 114);\n}\n.yellow {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background-color: #d97706;\n}\n.green {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n  background-color: #65a30d;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react'
import { Col, FormGroup, Row, Input, Form } from 'reactstrap';
import { Label } from 'reactstrap';
import Select from "react-select";
import { Link } from 'react-router-dom';
import { DatePicker } from 'antd';
import { Button } from '@mui/material';
import axios from 'axios';
import { BASE_Url } from '../../URL.js/URL';
import Swal from 'sweetalert2';
import { Tab, Tabs } from 'react-bootstrap';
import Statement from './Statement';
const Bahikhata = () => {
    const {saasId,storeId} = JSON.parse(localStorage.getItem('STORE_DATA'));
    const [formdata, setFormData] = useState({
        party: "",
        payment_type: "",
        payment_date: "",
        payment_mode: "",
        amount: "",
        saas_id: saasId,
        store_id :storeId,
        payment_notes: "",
        party_name: ""
    });
    //party options form get api call
    const [partyOptions, setPartyOptions] = useState([]);
    const getPartyOptions = async () => {
        const apiUrl = `${BASE_Url}/supplier/get-party-name/${saasId}/${storeId}`;
        try {
            const response = await axios.get(apiUrl);
            console.log("API response:", response.data);
            //set response in value and lable key pair for dropdown
            setPartyOptions(response.data.data.map(item => ({ value: item.values, label: item.values, id: item.id })));
            // setPartyOptions(response.data.data);
        } catch (error) {
            console.log("API request failed:", error);
        }
    }
    useEffect(() => {
        getPartyOptions()
    }, [saasId]);

    const handelonchange = (e) => {
        if (e.target) {
            setFormData({ ...formdata, [e.target.name]: e.target.value });
        }
    }

    const handleSelectChange = (selectedOption, actionMeta) => {
        console.log("Selected", selectedOption,actionMeta.name);
        setFormData({ ...formdata, [actionMeta.name]: selectedOption.value });
    }
    const handleDateChange = (date, dateString) => {
        setFormData({ ...formdata, payment_date: dateString });
    }
    
    const onSubmit = async(e) => {
        e.preventDefault();
        console.log(formdata);
        //check if all fields are filled
        if (!formdata.payment_type ||!formdata.payment_date ||!formdata.payment_mode ||!formdata.amount ||!formdata.party_name) {
            return Swal.fire({
                icon: 'error',
                title: 'Please Enter All Input Fields',
                showConfirmButton: false,
                timer: 2500
            });
        }
        try {
            const response = await axios.post(`${BASE_Url}/bahikhata/create-bahikhata`, formdata);
            console.log("API response:", response.data);
            //on suessful submission show message in swal
            if(response.data.status){
                Swal.fire({
                    title: response.data.message,
                    icon: "success",
                    timer: 2000
                });
                //reset form after successful submission
                setFormData({
                    party: "",
                    payment_type: "",
                    payment_date: "",
                    payment_mode: "",
                    amount: "",
                    saas_id: saasId,
                    store_id :storeId,
                    payment_notes: "",
                    party_name: ""
                });
            }else{
                Swal.fire({
                    title: response.data.message,
                    icon: "error",
                    timer: 2000
                });
            }
           
            
        } catch (error) {
            console.log("API request failed:", error);
        }
    }

    return (
        <>
        <Tabs
      defaultActiveKey="Bahikhata"
      id="uncontrolled-tab-example"
      className="mb-3  fw-bold"
      fill
      style={{background: '#FFF',
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
        }}
        // onSelect={(key)=>{
        //   setActivetab(key)
        // }}
        
    >
        <Tab eventKey="Bahikhata" title="Bahikhata">
        <div className='container flex justify-center overflow-y-auto max-h-[400px] md:max-h-[500px]'>
            <div className='max-w-[550px]   '>
                <Form onSubmit={onSubmit}>
                <div className="d-flex justify-center">
                    <Link to="/" type="submit" className="text-decoration-none" style={{ color: "black" }}>
                        <i className="fa-solid fa-chevron-left mt-1"></i>
                    </Link>
                    <h4 style={{ fontFamily: "bold", marginLeft: "10px" }}>Create Bahikhata</h4>
                </div>
                    <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>
                                Select Party <span className="text-red"> * </span>
                            </Label>
                            <Select
                                options={partyOptions}
                                name='party_name'
                                onChange={handleSelectChange}
                                placeholder="Select Party"
                            />
                        </FormGroup>
                    </Col>

                    <Col md={12}>
                        <FormGroup>
                            <Label>
                                Payment Type <span className="text-red"> * </span>
                            </Label>
                            <div className="d-flex">
                                <div className="me-4">
                                    <Input
                                        type="radio"
                                        className="me-2 mouse-pointer"
                                        id="received-radio"
                                        name="payment_type"
                                        value="received"
                                        onChange={handelonchange}
                                        checked={formdata.payment_type === "received"}
                                    />
                                    <Label htmlFor="received-radio" className="mouse-pointer">
                                        Received
                                    </Label>
                                </div>
                                <div className="me-4">
                                    <Input
                                        type="radio"
                                        className="me-2 mouse-pointer"
                                        id="given-radio"
                                        name="payment_type"
                                        value="given"
                                        onChange={handelonchange}
                                        checked={formdata.payment_type === "given"}
                                    />
                                    <Label htmlFor="given-radio" className="mouse-pointer">
                                        Given
                                    </Label>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col md={12}>
                        <FormGroup className='flex flex-col'>
                            <Label>
                                Payment Date <span className="text-red"> * </span>
                            </Label>
                            <DatePicker className='py-2' onChange={handleDateChange}/>
                        </FormGroup>
                    </Col>

                    <Col md={12}>
                        <FormGroup>
                            <Label>
                                Payment Mode <span className="text-red"> * </span>
                            </Label>
                            <div className="d-flex">
                                <div className="me-4">
                                    <Input
                                        type="radio"
                                        className="me-2 mouse-pointer"
                                        id="online-radio"
                                        name="payment_mode"
                                        value="online"
                                        onChange={handelonchange}
                                        checked={formdata.payment_mode === "online"}
                                    />
                                    <Label htmlFor="online-radio" className="mouse-pointer">
                                        Online
                                    </Label>
                                </div>
                                <div className="me-4">
                                    <Input
                                        type="radio"
                                        className="me-2 mouse-pointer"
                                        id="cheque-radio"
                                        name="payment_mode"
                                        value="cheque"
                                        onChange={handelonchange}
                                        checked={formdata.payment_mode === "cheque"}
                                    />
                                    <Label htmlFor="cheque-radio" className="mouse-pointer">
                                        Cheque
                                    </Label>
                                </div>
                                <div>
                                    <Input
                                        type="radio"
                                        className="me-2 mouse-pointer"
                                        id="cash-radio"
                                        name="payment_mode"
                                        value="cash"
                                        onChange={handelonchange}
                                        checked={formdata.payment_mode === "cash"}
                                    />
                                    <Label htmlFor="cash-radio" className="mouse-pointer">
                                        Cash
                                    </Label>
                                </div>
                            </div>
                        </FormGroup>
                    </Col>

                    <Col md={12}>
                        <FormGroup>
                            <Label>
                                Amount <span className="text-red"> * </span>
                            </Label>
                            <Input
                                style={{ fontStyle: "italic", fontFamily: "Arial, sans-serif" }}
                                type="number"
                                name="amount"
                                value={formdata.amount}
                                onChange={handelonchange}
                                required
                                placeholder="Enter Amount"
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12}>
                        <FormGroup>
                            <Label>
                                Payment Notes <span className="text-red"> * </span>
                            </Label>
                            <Input
                                style={{ fontStyle: "italic", fontFamily: "Arial, sans-serif" }}
                                type="textarea"
                                name="payment_notes"
                                value={formdata.payment_notes}
                                onChange={handelonchange}
                                required
                                placeholder="Enter Payment Notes"
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <div className='justify-center flex '>
                    <Button type='submit' variant="contained">Create Bahikhata</Button>
                </div>
                </Form>

            </div>
        </div>
            </Tab>
            <Tab eventKey="Statement" title="Financial">
                <Statement />
            </Tab>
            
    </Tabs>
        
        
        </>)
}

export default Bahikhata;

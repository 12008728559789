import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  // useEffect(() => {
  //   alert("HELLO");
  // }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`${BASE_Url}/auth/user-login`, {
        user_name: userName,
        password: password,
      })
      .then((res) => {
        console.log("RES", res);
        if (res.data.status) {
          // toast.success(res.data.message);
          localStorage.setItem(
            "Token",
            JSON.stringify(res.data.data.jwt_response)
          );
          localStorage.setItem(
            "STORE_DATA",
            JSON.stringify(res.data.data.store_data)
          );
          localStorage.setItem(
            "USER_DATA",
            JSON.stringify(res.data.data.user_data)
          );
          // setTimeout(() => {
          navigate("/");
          // }, 1000);
        }else{
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Invalid Username or Password",
          });
        }
      })
      .catch((err) => console.log("ERR", err));
  };
  return (
    <section className="text-gray-600 body-font ">
      <div className="container px-5 py-24 mx-auto flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="lg:w-1/2 md:w-full bg-blue-950 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md"
        >
          <div className="flex items-center justify-center text-lg">
            <h2 className="text-gray-100 text-lg mb-1 font-medium title-font">
              Login
            </h2>
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-100">
              User Name
            </label>
            <input
              type="text"
              id="email"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              name="email"
              placeholder="Enter User Name"
              className="w-full bg-white rounded border border-gray-300 focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="password"
              className="leading-7 text-sm text-gray-100"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              placeholder="Enter Password"
              className="w-full bg-white rounded border border-gray-300 focus:ring-2 focus:ring-pink-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>

          <button
            type="submit"
            className="text-white bg-violet-600 border-0 py-2 px-6 focus:outline-none hover:bg-violet-800 rounded text-lg"
          >
            Login
          </button>
          <p className="text-xs text-gray-100 mt-3">
            Don’t have an account?
            <Link to="/register"> Signup</Link>
          </p>
        </form>
      </div>
    </section>
  );
};

export default Login;

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
// import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import musicb from "../assets/musicb.mp3";
import "bootstrap/dist/css/bootstrap.min.css";
import { useReactToPrint } from "react-to-print";
import Orders from "./Orders";
import Reports from "./Reports";
import { Link, useNavigate } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";
import { BASE_Url } from "../URL.js/URL";
import zIndex from "@mui/material/styles/zIndex";
import TableToPrint from "./TableToPrint";
import useSound from "use-sound";
import { StatusFilter } from "../redux/slices/dashboardStatus";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";
const Dashboard = () => {
  const [selectedButton, setSelectedButton] = useState("Pending");
  const navigate = useNavigate();
  const [itemData, setItemData] = useState([]);
  const [playenow, setPlayenow] = useState(false);
  const [play] = useSound(musicb);
  const printRef = useRef();
  const dispatch = useDispatch();
  const HandleButton = (status) => {
    setSelectedButton();
    dispatch(StatusFilter());
    // console.log("gautam", status);
  };
  // useEffect(() => {
  //   if (playenow) {
  //     play();
  //   }
  // }, [playenow]);

  const [status, setStatus] = useState("pending");
  const [orderType, setOrderType] = useState("Dine In");

  const GetOrderByStatus = () => {
    // play()
    const Token = JSON.parse(localStorage.getItem("Token"));
    if (!Token) {
      navigate("/login");
    } else {
      const { storeId, saasId } = JSON.parse(
        localStorage.getItem("STORE_DATA")
      );
      const apiUrl = `${BASE_Url}/order/get-order-by-status/${saasId}/${storeId}/${orderType}/${status}`;
      axios
        .get(apiUrl)
        .then((response) => {
          console.log("dsorder", response);
          if (response.status === 200) {
            const data = response.data;
            // Assuming you want to display the data for the fourth item (index 3)
            // setItemData(data.data.item_list[3]);
            console.log(
              "response.data.data.length>itemData.length",
              response.data.data.length > itemData.length
            );
            // if (response.data.data.length > itemData.length) {
            //   setPlayenow(true);
            // }

            // const jsondata = data.data.map((item)=>{
            //   item['Approved']= "panding"
            //   return item
            // })
            // console.log("modify",jsondata)
            setItemData(data.data);
          } else {
            console.log("Failed to fetch data from the API.");
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  };

  useEffect(() => {
    GetOrderByStatus();
  }, [status, orderType]);

  const updateOrderStatus = (orderId, OrderStatus) => {
    const updateStatusUrl = `${BASE_Url}/order/update-approval-status/${orderId}/${OrderStatus}`;

    // Make a PUT request to update the status
    axios
      .put(updateStatusUrl, null, {
        headers: {
          "Content-Type": "application/json",

          // Add any other headers if needed
        },
      })
      .then((updateResponse) => {
        console.log("Order status updated successfully:", updateResponse);
        GetOrderByStatus();
        // Update the itemData state or perform other actions if needed
        // setItemData(updatedData);
      })
      .catch((updateError) => {
        console.error("Failed to update order status:", updateError);
      });
  };

  const Update = (data, status) => {
    const newArr = [...itemData];
    const findIndex = newArr.findIndex((el) => el.order_id == data.order_id);
    //  const updatedArray= newArr[findIndex].status=status
    const updateUrl = `${BASE_Url}/order/update-order-status/${data.order_id}/${status}`;

    axios.put(updateUrl).then((response) => {
      if (response.status === 200) {
        newArr[findIndex].status = status;

        setItemData(newArr);
        const updateData = response.data;
        // console.log(itemData);
        GetOrderByStatus();
      }
    });
  };

  //  <----------handelPrint----------->
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  // <---------------This Order Data --------->
  const [orderData, setData] = useState("");
  const getDataByorder = async (OderID) => {
    try {
      const response = await axios.get(
        `${BASE_Url}/order/view-order-details/${OderID}`
      );
      if (response.status) {
        console.log("this is response", response);
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [open, setOpen] = useState(false);
  const [accept, setAcept] = useState(false);
  const [reject, setReject] = useState(false);
  // const handleAccept = (data) => {
  //   updateOrderStatus(data.order_id, "preparing");

  //   const updatedItemData = itemData.map((el) =>
  //     el.order_id === data.order_id ? {
  //       ...el,
  //       approval_status: "Approved"
  //     } : el
  //   );
  //   console.log(updatedItemData)
  //   setItemData(updatedItemData);
  // }
  // const handleReject = (data) => {
  //   updateOrderStatus(data.order_id, "reject");

  //   const updatedItemData = itemData.map((el) =>
  //     el.order_id === data.order_id ? { ...el, approval_status: "reject" } : el
  //   );
  //   setItemData(updatedItemData);
  // };
  const getBorderStyle = () => {
    if (status === "preparing") {
      return "3px solid #E1D98C";
    } else if (status === "pending") {
      return "3px solid powderblue";
    } else if (status === "serve") {
      return "3px solid lightgreen";
    } else {
      return "3px solid rgb(245, 158, 11)";
    }
  };
  const activeButtonStyle = {
    borderBottom: getBorderStyle(), // Add your preferred color for the bottom border
    background: "#0E2954",
    color: "white",
    padding: "5px 20px",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "all 0.3s ease",
  };
  return (
    <div>
      <div
        className="flex gap-10 d-flex justify-around fw-bold mt-2 mx-2"
        style={{
          overflowX: "auto",
          boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
          borderRadius: "0.75rem",
          overflow: "hidden",
          fontFamily: "DM sans-serif",
          fontSize: "1.25rem",
          border: "0 2px 12px rgba(36, 36, 39, .12)",
          padding: "20px",
        }}
      >
        
        <button
          style={{ ...(orderType === "Delivery" ? activeButtonStyle : {}) }}
          onClick={() => {
            setOrderType("Delivery");
            setStatus("pending");
          }}
        >
          Delivery
        </button>
        <button
          style={{ ...(orderType === "Pickup" ? activeButtonStyle : {}) }}
          onClick={() => {
            setOrderType("Pickup");
            setStatus("pending");
          }}
        >
          Take-Out
        </button>
        <button
          style={{ ...(orderType === "Dine In" ? activeButtonStyle : {}) }}
          onClick={() => {
            setOrderType("Dine In");
            setStatus("pending");
          }}
        >
          Dine In
        </button>
      </div>
      <div
        className="flex gap-10 d-flex justify-around fw-bold mt-2 mx-2"
        style={{
          overflow: "auto",
          whiteSpace: "nowrap",
          boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
          borderRadius: "0.75rem",
          fontFamily: "DM sans-serif",
          fontSize: "1.25rem",
          padding: "20px",
          scrollbarWidth: "none", // Optional: hide scrollbar in Firefox
        }}
      >
        <button
          style={{ ...(status === "pending" ? activeButtonStyle : {}) }}
          onClick={() => setStatus("pending")}
        >
          Pending
        </button>
        <button
          style={{ ...(status === "preparing" ? activeButtonStyle : {}) }}
          onClick={() => setStatus("preparing")}
        >
          Preparing
        </button>
        {orderType === "Dine In" && (
          <button
            style={{ ...(status === "serve" ? activeButtonStyle : {}) }}
            onClick={() => setStatus("serve")}
          >
            Serve
          </button>
        )}
        {orderType === "Delivery" && (
          <>
            <button
              style={{
                ...(status === "Out for Delivery" ? activeButtonStyle : {}),
              }}
              onClick={() => setStatus("Out for Delivery")}
            >
              Out for Delivery
            </button>
            <button
              style={{ ...(status === "Delivered" ? activeButtonStyle : {}) }}
              onClick={() => setStatus("Delivered")}
            >
              Delivered
            </button>
          </>
        )}
        {orderType === "Pickup" && (
          <>
            <button
              style={{ ...(status === "Ready" ? activeButtonStyle : {}) }}
              onClick={() => setStatus("Ready")}
            >
              Ready
            </button>
            <button
              style={{ ...(status === "Picked" ? activeButtonStyle : {}) }}
              onClick={() => setStatus("Picked")}
            >
              Picked
            </button>
          </>
        )}
      </div>

      <div style={{ height: "100vh", overflowY: "auto" }}>
        {itemData.length > 0 ? (
          <div
            className="flex flex-wrap flex-row mx-2"
            style={{ marginBottom: "40%" }}
          >
            {itemData.map((data) => {
              return (
                <div
                  className="w-full max-w-[18rem] rounded-lg border-2 border-gray-300 bg-white m-4 ml-2"
                  style={{ position: "relative" }}
                >
                  <div
                    className="flex justify-between "
                    style={{ borderRadius: " 8px 8px 0px 0px" }}
                  >
                    <div className="flex flex-col">
                    <li
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: " normal",
                      }}
                      className="w-full list-none	 font-medium  border-gray-300  p-2"
                    >
                      Order id: {data.order_id}
                    </li>
                   { data.table_name &&<li
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: " normal",
                      }}
                      className="w-full list-none	 font-medium  border-gray-300  p-2"
                    >
                      Table : {data.table_name}
                    </li>}

                    </div>
                    {data.approval_status == "Accept" && (
                      <div
                        className="fw-bold mr-4 mt-2"
                        style={
                          {
                            // position: "relative",
                            // top: "-10px",
                            // right: "-36px"
                          }
                        }
                      >
                        <Dropdown className=" ">
                          <Dropdown.Toggle
                            className="dropdown-toggle px-2 "
                            variant=""
                            id="dropdown-basic"
                            // key={data.status}
                            // onClick={() => Update(data.status)}
                            style={{
                              background:
                                data.status === "preparing"
                                  ? "#E1D98C"
                                  : data.status === "pending"
                                  ? "powderblue"
                                  : data.status === "serve"
                                  ? "lightgreen"
                                  : "rgb(245 158 11)",
                              zIndex: 999,
                              fontFamily: "Inter",
                              fontStyle: "normal",
                              fontWeight: "700",
                              lineHeight: "normal",
                            }}
                          >
                            {data.status}
                          </Dropdown.Toggle>

                          <Dropdown.Menu style={{ zIndex: 999 }}>
                            <Dropdown.Item
                              onClick={() => Update(data, "pending")}
                              style={{
                                zIndex: 999,
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                              }}
                            >
                              Order Received
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => Update(data, "preparing")}
                              style={{
                                zIndex: 999,
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                              }}
                            >
                              preparing
                            </Dropdown.Item>
                            {orderType === "Dine In" && (
                              <Dropdown.Item
                                style={{
                                  zIndex: 999,
                                  fontFamily: "Inter",
                                  fontStyle: "normal",
                                  fontWeight: "700",
                                  lineHeight: "normal",
                                }}
                                onClick={() => {
                                  Update(data, "serve");
                                  // navigate(`/finishorder/${data.order_id}`);
                                }}
                              >
                                Ready to Serve
                              </Dropdown.Item>
                            )}
                            {orderType === "Delivery" && (
                              <>
                                <Dropdown.Item
                                  style={{
                                    zIndex: 999,
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                  }}
                                  onClick={() => {
                                    Update(data, "Out for Delivery");
                                    // navigate(`/finishorder/${data.order_id}`);
                                  }}
                                >
                                  Out for Delivery
                                </Dropdown.Item>
                                <Dropdown.Item
                                  style={{
                                    zIndex: 999,
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                  }}
                                  onClick={() => {
                                    Update(data, "Delivered");
                                    // navigate(`/finishorder/${data.order_id}`);
                                  }}
                                >
                                  Delivered
                                </Dropdown.Item>
                              </>
                            )}
                            {orderType === "Pickup" && (
                              <>
                                <Dropdown.Item
                                  style={{
                                    zIndex: 999,
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                  }}
                                  onClick={() => {
                                    Update(data, "Ready");
                                    // navigate(`/finishorder/${data.order_id}`);
                                  }}
                                >
                                  Ready
                                </Dropdown.Item>
                                <Dropdown.Item
                                  style={{
                                    zIndex: 999,
                                    fontFamily: "Inter",
                                    fontStyle: "normal",
                                    fontWeight: "700",
                                    lineHeight: "normal",
                                  }}
                                  onClick={() => {
                                    Update(data, "Picked");
                                    // navigate(`/finishorder/${data.order_id}`);
                                  }}
                                >
                                  Picked
                                </Dropdown.Item>
                              </>
                            )}
                            {/* <Dropdown.Item
                        onClick={() => Update(data, "foodordering")}
                      >
                        foodordering
                      </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    )}
                    {/* <BiSolidChevronRight className="items-center m-6 font-bold"></BiSolidChevronRight> */}
                  </div>

                  {/* <div className="flex" style={{ zIndex: 1, position: "relative" }}>
                <li className="w-full list-none font-semibold ml-2 py-2">
                  {data.customer_name == null
                    ? "Walk in Custumer"
                    : data.customer_name}
                </li>

                <span className="font-semibold list-none flex items-center justify-between px-4">
                  {data.time}
                </span>
              </div> */}
                  <hr className="m-0 mx-3 " />

                  <div className="flex mt-1 w-1/2 bg-[#0e2954] rounded text-white  ">
                    <li
                      className="w-full fw-bold mx-4 py-3 list-none text-nowrap flex justify-center "
                      style={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "normal",
                      }}
                    >
                      {data.order_type}
                    </li>
                  </div>
                  <div className="flex justify-start font-bold mt-3 w-fit mx-3">
                    <span>
                      Products/Qty
                    </span>
                    {/* <span>
                      Qty
                    </span> */}
                  </div>
                  <hr className="m-0" />

                  <div className="d-flex h-24">
                    <div
                      style={{
                        maxHeight: "100px",
                        marginBottom: "10px",
                        overflowY: "auto",
                        minWidth: "18rem",
                      }}
                    >
                      <div class="flex flex-col">
                        <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                            <div class="overflow-hidden">
                              <table className="w-full mb-4">
                                  {/* <thead>
                                    <tr>
                                      <th className="text-left pb-2">Product</th>
                                      <th className="text-left pb-2">Qty</th>
                                    </tr>
                                  </thead> */}
                                <tbody>
                                {data.item_name_list.map((item, index) => {
                          return (<tr>
                                    <td className="py-2">
                                      <span key={index}
                              className="px-3 rounded-2 text-nowrap font-medium mt-3 mb-3 list-none"
                              style={{
                                background:
                                  data.status === "preparing"
                                    ? "#E1D98C"
                                    : data.status === "pending"
                                    ? " powderblue"
                                    : data.status === "serve"
                                    ? "lightgreen"
                                    : "rgb(245 158 11)",
                                zIndex: 999,
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                              }}>
                                         {item.item_name.length > 15?item.item_name.slice(0, 15) + "...":item.item_name}
                                      </span>
                                    </td>
                                    <td className="py-2 px-2">{item.item_quantity}</td>
                                  </tr>)})}

                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <ul>
                        {data.item_name_list.map((item, index) => {
                          return (
                            <>
                            <li>Product</li>
                            <li
                              key={index}
                              className="px-3 rounded-2 text-nowrap font-medium mt-3 mb-3 list-none"
                              style={{
                                background:
                                  data.status === "preparing"
                                    ? "#E1D98C"
                                    : data.status === "pending"
                                    ? " powderblue"
                                    : data.status === "serve"
                                    ? "lightgreen"
                                    : "rgb(245 158 11)",
                                zIndex: 999,
                                fontFamily: "Inter",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                              }}
                            >
                              {item.item_name.length > 15?item.item_name.slice(0, 15) + "...":item.item_name}
                            </li>
                            </>);
                        })}
                      </ul> */}
                    </div>
                   
                  </div>
                  <hr className="m-0" />

                  <div className=" mt-2  ">
                    {data.approval_status === "pending" && (
                      <div className="flex justify-center">
                        <Button
                          className="mx-3"
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            updateOrderStatus(data.order_id, "Accept")
                          }
                        >
                          Accept
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            updateOrderStatus(data.order_id, "Reject")
                          }
                        >
                          Reject
                        </Button>
                      </div>
                    )}
                  </div>

                  {data?.approval_status == "Accept" ? (
                    <div>
                      <div className="font-bold mb-3">
                        {data.status !== "serve" &&
                          data.status !== "Delivered" &&
                          data.status !== "Picked" && (
                            <div className="flex justify-center">
                              <Button
                                className="mx-3"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  navigate(`/Update?orderId=${data.order_id}`)
                                }
                              >
                                Update
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  getDataByorder(data.order_id);
                                  setOpen(true);
                                }}
                              >
                                Print
                              </Button>
                            </div>
                          )}
                      </div>

                      <div className=" font-bold   mb-3  ">
                        <div>
                          {(data.status === "serve" ||
                            data.status === "Picked" ||
                            data.status === "Delivered") && (
                            <div className="flex justify-center">
                              <Button
                                className="mx-3"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  navigate(`/Update?orderId=${data.order_id}`)
                                }
                              >
                                Update
                              </Button>
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  // Update(data, "Ready_to_Serve");
                                  navigate(`/finishorder/${data.order_id}`);
                                }}
                              >
                                Bill
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-center mb-3"
                      style={{ flexWrap: "wrap" }}
                    >
                      {data.approval_status === "Reject" && (
                        <span
                          className=""
                          style={{
                            color: "rgba(0, 0, 0, 0.70)",

                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: " normal",
                          }}
                        >
                          Your Order Is Rejected{" "}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="flex justify-center align-items-center h-50">
            <div className="">
              <div className="d-flex justify-center align-items-center ">
                <p
                  style={{ color: "#B2B1B1", fontFamily: "sans-serif" }}
                  className="text-xl font-semibold"
                >
                  No Order to Show yet
                </p>
              </div>
              <div className="d-flex justify-center align-items-center mt-4">
                <img src="/DataNotFound.png" alt="No Data Found" />
              </div>
            </div>
          </div>
        )}
        {orderData && (
          <TableToPrint
            setOpen={setOpen}
            orderData={orderData}
            open={open}
            handlePrint={handlePrint}
            printRef={printRef}
          />
        )}
      </div>
    </div>
  );
};

export default Dashboard;

import { configureStore } from "@reduxjs/toolkit";
import { tableReducer } from "./slices/tableSlice";
import { menuReducer, selectReducer } from "./slices/selectOrdersSlice";
import  usermanagementReducer  from "./slices/userManagement";
import dasboardStatusReducer from "./slices/dashboardStatus"

const store = configureStore({
  reducer: {
    tableData: tableReducer,
    selectOrders: selectReducer,
    userManagement:usermanagementReducer,
    dasboardStatus:dasboardStatusReducer
  },
});

export default store;

import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteCategory = ({DeleteCategorybyid,categories}) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);
  return (
    <div>
        <Button  style={{background:"#0E2954"}}  className=" text-white semibold rounded md:mt-0 mx-2"
            onClick={toggle}
          >
            Delete Category
          </Button>
    {/* <Button color="danger" onClick={toggle}>
      Click Me
    </Button> */}
    <Modal isOpen={modal} toggle={toggle} >
      <ModalHeader toggle={toggle}>Delete Category</ModalHeader>
      <ModalBody>
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="category table">
        <TableHead>
          <TableRow>
            <TableCell>Category ID</TableCell>
            <TableCell>Category Name</TableCell>
            {/* <TableCell>SaaS ID</TableCell>
            <TableCell>Store ID</TableCell> */}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.map((category) => (
            <TableRow
              key={category.category_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {category.category_id}
              </TableCell>
              <TableCell>{category.category_name}</TableCell>
              {/* <TableCell>{category.saas_id}</TableCell> */}
              {/* <TableCell>{category.store_id}</TableCell> */}
              <TableCell>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  onClick={() => DeleteCategorybyid(category.category_id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </ModalBody>
      {/* <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Do Something
        </Button>{' '}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter> */}
    </Modal>
  </div>
  )
}

export default DeleteCategory
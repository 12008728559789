import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { BASE_Url } from '../../URL.js/URL';
import chola from "../../images/chola.jpg";
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Paper, Grid } from '@mui/material';
const Myorder = ({setShow,setStatus}) => {
    const params = useParams();
  const {saas_id, store_id ,table_id} = params;
    const navigate = useNavigate();
    const [order, setOrder] = useState([])
    const myorders = () => {
        axios
          .get(
            `${BASE_Url}/order/view_order_ByTable/${saas_id}/${store_id}/${table_id}`
          )
          .then((res) => {
            if (res.status === 200) {
              console.log("recommended DATA", res);
              setOrder(res.data.data);
            }
          })
          .catch((err) => console.log(err));
      };




    useEffect(() => {
        myorders()
    }, [])
     
    const handlestatus = (item)=>{
        if(item.status == "Order Recevied") return 1
        if(item.status == "preparing") return 2
        if(item.status == "serve") return 4
         else return 1
    }
    
  return (
    <div >
        <div className="mb-5 " style={{ maxWidth: "700px", margin: "auto" }}>
        <div className='d-flex bg-yellow pt-2 pb-3 mb-2'>
                <svg className='mt-2 mx-2' onClick={() => { navigate(-1) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 9 14" fill="none">
                    <path d="M8 1L2 7L8 13" stroke="black" stroke-width="2" stroke-linecap="round" />
                </svg>
                <span className='d-flex align-items-center text-2xl  fst-normal '>My Orders</span>
            </div>
            <div style={{ height: "80vh", overflowY: "auto"}}>
            <div className="mb-5" style={{marginBottom:"40%"}} >
      {order && (
        <>
          {order?.map((item) => (
            <div onClick={()=>{
                setShow(true)
                setStatus(handlestatus(item))}
            }>

<Paper 
className='mx-2 mt-1'
      elevation={3} 
      sx={{
        padding: '16px',
        maxWidth: '400px',
        margin: 'auto',
        backgroundColor: '#f9f9f9',
        // boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
        borderRadius: "0.75rem",
      }}
    >
      <Box 
        sx={{
          borderBottom: '1px dashed #ccc',
          marginBottom: '16px'
        }}
      >
        <Typography variant="subtitle2" color="textSecondary">
          Order id : {item.order_id}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          06:30AM &nbsp;&nbsp;  {item.time}
        </Typography>
      </Box>
      
      <Grid className=' mt-2' container direction="column" spacing={1}>
        <Grid item>
        {item.item_names.map((i, key)=>{
                        return   (<Typography>
            {key+1} : {i}
          </Typography>)
                    })}
        </Grid>
      
     
      </Grid>
      
      <Box 
        sx={{
          borderTop: '1px dashed #ccc',
          marginTop: '16px',
          paddingTop: '8px'
        }}
      >
        <Typography variant="subtitle1">
        OrderType : {item.order_type}
        </Typography>
      </Box>
    </Paper>

              
              {/* <div
                className="flex flex-row justify-between items-center bg-white border mt-3  md:flex-row  pl-3"
                style={{ width: "100%" }}
              >
                <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                  <img
                    className="h-full w-full object-cover object-center "
                    src={chola}
                  />
                </div>
                <div className="flex flex-col justify-between pl-2 leading-normal">
                
                    {item.item_names.map((i)=>{
                        return   (<p className="mb-2 text-xl font-medium lowercase tracking-tight text-gray-900 dark:text-white">
                                  {i}</p>)
                    })}
                    
                    </div>
                    <div  >
                     <h5 className='text-nowrap mr-2'>Order Id:{item.order_id}</h5>
                     <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-900 dark:text-white pr-4">
                  {item.time}
                </h5>
                    </div>
                 
                
                
              </div> */}
            </div>
          ))}
      </>)} </div> </div>
        </div>
        
    </div>
  )
}

export default Myorder
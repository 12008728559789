import React, { useState, useEffect } from 'react';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import 'react-vertical-timeline-component/style.min.css';
import './OrderStyle.css';
import { useNavigate } from 'react-router-dom';
import Myorder from './Myorder';

const OrderStatus = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState(1);
    const [show, setShow] = useState(false);
    const [timeStamps, setTimeStamps] = useState({});

    const arr = [
        {
            status: "Order Received",
            value: 1
        },
        {
            status: "Food is being prepared",
            value: 2
        },
        {
            status: "Your food is ready",
            value: 3
        },
        {
            status: "Awaiting for Food",
            value: 4
        }
    ];

    useEffect(() => {
        if (!timeStamps[status]) {
            const now = new Date();
            const formattedTime = now.getHours().toString().padStart(2, '0') + ':' + now.getMinutes().toString().padStart(2, '0');
            setTimeStamps(prev => ({ ...prev, [status]: formattedTime }));
        }
    }, [status, timeStamps]);

    return (
        <>
            {show ? (
                <div className="container p-0">
                    <div className='d-flex bg-yellow pt-2 pb-3'>
                        <svg className='mt-2 mx-2' onClick={() => { setShow(false) }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 9 14" fill="none">
                            <path d="M8 1L2 7L8 13" stroke="black" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                        <span className='d-flex align-items-center text-2xl fst-normal'>Order Status</span>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div id="tracking-pre"></div>
                            <div id="tracking">
                                <div className="tracking-list">
                                    {arr.map((item) => (
                                        <div style={{marginTop:"1px"}} className={`${item.value <= status ? "tracking-item" : "tracking-item-pending"}`} key={item.value}>
                                            <div className="tracking-icon status-intransit">
                                                <svg className="svg-inline--fa fa-circle fa-w-16" aria-hidden="true" data-prefix="fas" data-icon="circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                                                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
                                                </svg>
                                            </div>
                                            <div className="tracking-date">
                                                <img src="https://raw.githubusercontent.com/shajo/portfolio/a02c5579c3ebe185bb1fc085909c582bf5fad802/delivery.svg" className="img-responsive" alt="order-placed" />
                                            </div>
                                            <div className="tracking-content">
                                                {item.status}
                                                <span>{timeStamps[item.value]}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <Myorder
                    setShow={setShow}
                    setStatus={setStatus}
                />
            )}
        </>
    );
};

export default OrderStatus;

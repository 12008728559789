import { Payment } from "@mui/icons-material";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { promiseHandler } from "../promiseHandler";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";



export const getAllUserList = createAsyncThunk("usermanagement/getAllUserList",
promiseHandler(async(params)=>{
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const url=params!=="User Master"?`${BASE_Url}/staff-user/get-role-details/${saasId}/${storeId}`:`${BASE_Url}/staff-user/get-staff-details/${saasId}/${storeId}`
  const response= await axios.get(url)
  // const getAllRole=)
   return response
})
)



export const getUpdateUserList = createAsyncThunk("usermanagement/getUpdateUserList",
promiseHandler(async(params)=>{
  console.log("body",params.staffId,params.updatebody)
  const url=`${BASE_Url}/staff-user/update-staff/${params.staffId}`
  const response= await axios.put(url,params.updatebody)
  // const getAllRole=)
   return response
   
})
)

export const getUpdateRoleList = createAsyncThunk("usermanagement/getUpdateRoleList",
promiseHandler(async(params)=>{
  console.log("bodyr",params.roleId,params.updaterole)
  const url=`${BASE_Url}/staff-user/update-role/${params.roleId}`
  const response= await axios.put(url,params.updaterole)
  // const getAllRole=)
   return response
   
})
)




const userManagementSlice = createSlice({
  name: "usermanagement",
  initialState: {
    list: [],
    loading:false,
    error:""
  },
  reducers: {
    
  },
  extraReducers:builder=>{
    builder.addCase(getAllUserList.pending,(state,action)=>{
        state.loading=true;
        state.error=null
    })
    builder.addCase(getAllUserList.rejected,(state,error)=>{
        state.loading=false;
        state.error=error
    })
    builder.addCase(getAllUserList.fulfilled,(state,action)=>{
        state.list=action.payload.data.data;
        state.loading=false;
        state.error=null
    })
  }
});


// for exporting in store(reducer)
export default userManagementSlice.reducer;

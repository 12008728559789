import React from 'react';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Row from 'react-bootstrap/Row';

function InventoryModal(props) {
    const { data,onHide ,Showitem} = props;
    const columns = [
        {
            name: 'Raw material',
            selector: 'raw_material',
            center: true,
        },
        {
            name: 'Quantity',
            selector: 'quantity',
            center: true,
        },
        {
            name: 'Cost',
            selector: 'cost_of_material',
            center: true,
        },
    ];

    return (
        <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='p-0'>
                <Row>
                <div className="bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto relative">
      <button onClick={()=>{onHide()}} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </button>
      <h2 className="text-2xl font-bold mb-2">{Showitem}</h2>
      <p className="text-gray-600 mb-4">
        A classic Indian dish.
      </p>
      <div className="mb-4">
        <h3 className="font-semibold mb-2">Ingredients:</h3>
        <ul className="list-disc list-inside space-y-1">
          {data && data.map((item)=>{return(<li>{item.raw_material} - {item.quantity} {item.uom}</li>)})}
          {/* <li>Yogurt - 200 ml</li>
          <li>Tomato Sauce - 300 ml</li>
          <li>Cream - 100 ml</li> */}
        </ul>
      </div>
      {/* <div>
        <h3 className="font-semibold mb-2">Instructions:</h3>
        <ol className="list-decimal list-inside space-y-1">
          <li>Marinate chicken in yogurt and spices.</li>
          <li>Grill chicken until cooked.</li>
          <li>Prepare sauce with tomatoes and cream.</li>
          <li>Combine chicken and sauce, simmer for 10 minutes.</li>
        </ol>
      </div> */}
    </div>
                    {/* {data && (
                        <DataTable
                            columns={columns}
                            data={data}
                            responsive={true}
                            pagination
                            paginationServer
                            paginationTotalRows={data.length}
                        />
                    )} */}
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default InventoryModal;

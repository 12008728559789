import React, { useEffect, useState } from "react";
import { BsArrowLeft, BsTypeH1 } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { AiOutlineSearch } from "react-icons/ai";
import { useSelect } from "@material-tailwind/react";
import { BASE_Url } from "../../URL.js/URL";
import axios from "axios";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import InventoryModal from "./InventoryModal";
import { Button, Divider, Grid, IconButton, InputBase, MenuItem, Paper, Select, TextField } from "@mui/material";
import { Row } from "reactstrap";
import SearchIcon from "@mui/icons-material/Search";
import AddInventoryItemModal from "./AddInventoryItemModal";
const Inventory = () => {
const [inventryShow, setInventryShow] = useState(false);

  const [data, setData] = useState([]);


  const [totalRows, setTotalRows] = useState(0); 
  const getAllData = (pageNumber) => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));

    axios
      .get(`${BASE_Url}/getall-inventory-bom/${saasId}/${storeId}/${pageNumber}`)
      .then((res) => {
        if (res.status === 200) {
          console.log("TABLE", res);
          setData(res.data.data);
          setTotalRows(res.data.count);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    // Fetch initial data or perform any necessary setup
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA")) || {};
    if (storeId && saasId) {
      getAllData(1, storeId, saasId);
    }
  }, []); 
  // ---------------------------
  const columns = [
    {
      name: "Item Name",
      center: true,
      selector: (row) => row.inventory_name,
    },
    {
      name: "Item Code",
      center: true,
      selector: (row) => row.id,
    },
    {
      name: "Cost",
      center: true,
     selector: (row) => row.cost,
    },

    {
      name: "Quantity ",
      center: true,
      selector: (row) => row.opening_qty,
    },
    {
      name: "Unit", 
      center: true,
      selector: (row) => row.unit,
    },
  ];



  const [modalShow, setModalShow] = useState(false);
  // ---------------------------






  return (

    <>
    <div className="container">
    <Grid container className="my-3 px-3" spacing={2} alignItems="center">
      {/* <Grid item xs={12} sm={6}>
        <Select
          fullWidth
          value={state}
          onChange={(e) => setState(e.target.value)}
          aria-label="Default select example"
        >
          <MenuItem value="Inventory">Inventory</MenuItem>
          <MenuItem value="Bill of Materials">Recipe</MenuItem>
        </Select>
      </Grid> */}
      {/* <Grid item xs={12} sm={2}>
        {state === 'Bill of Materials' ? (
          <Button
            fullWidth
            variant="contained"
            style={{background:"black"}}
            onClick={() => navigate('/addBOM')}
          >
           Add Recipe
          </Button>
         ) : (
          <Button 
          onClick={() => setInventryShow(true)}
          fullWidth variant="contained">Add Item</Button>
        )} 
      </Grid> */}
      <Button 
          onClick={() => setInventryShow(true)}
          fullWidth variant="contained">Add Raw Material</Button>
    </Grid>
      <div className="row">
        
        {  data.length > 0 && (
            <div style={{ height: '400px', overflowY: 'scroll' }}>
            <DataTable
            columns={columns}
            responsive={true}
            data={data}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationPerPage={10}
            onChangePage={(page) => getAllData(page)}
            />
             </div> 
             
            )}
        
      </div>
    </div>

      <AddInventoryItemModal getAllData={getAllData} show={inventryShow} onHide={() => setInventryShow(false)} />
    </>
  );
};

export default Inventory;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { promiseHandler } from "../promiseHandler";
import axios from "axios";


export const StatusFilter = createAsyncThunk("usermanagement/StatusFilter",
promiseHandler(async(params)=>{
  const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
  const url=`http://52.66.154.68:8093/prod/api/v1/order/get-order-by-status/${saasId}/${storeId}/preparing`
  const response= await axios.get(url)
   return response  
})
)

const dasboardStatusSlice = createSlice({
    name: "dasboardStatus",
    initialState: {
      list: [],
      loading:false,
      error:""
    },
    reducers: {
      
    },
    extraReducers:builder=>{
      builder.addCase(StatusFilter.pending,(state,action)=>{
          state.loading=true;
          state.error=null
      })
      builder.addCase(StatusFilter.rejected,(state,error)=>{
          state.loading=false;
          state.error=error
      })
      builder.addCase(StatusFilter.fulfilled,(state,action)=>{
          state.list=action.payload.data.data;
          state.loading=false;
          state.error=null
      })
    }
  });

  export default dasboardStatusSlice.reducer;
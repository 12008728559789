import { Button, Divider, Grid, IconButton, InputBase, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import InventoryModal from "../inventory/InventoryModal";
import axios from "axios";
import { BASE_Url } from "../../URL.js/URL";
import Swal from "sweetalert2";
const Recipe = () => {
    const [selectMenu, setSelectMenu] = useState([]);
    const [category, setCategory] = useState([]);
    const [id, setID] = useState();
    const [search, setSearch] = useState("");
    const [screenWidth, setScreenWidth] = useState(0);
    const [activeCategory, setActiveCategory] = useState([]);
    const [sendCategory, setSendCategory] = useState("");
    const [BOMData, setBOMData] = useState([]);
    const [Showitem, setShowItem] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate();
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const getMenu = () => {
        axios
          .get(`${BASE_Url}/item/view-menu/${id}/${saasId}`)
          .then((res) => {
            console.log("MENU", res.data.data.item_list);
            setSelectMenu(res.data.data.item_list);
            // setActiveCategory(res.data.data.item_list);
    
          })
          .catch((err) => console.log(err));
      };
    
      useEffect(() => {
        if(id){
          getMenu();
        }
      }, [id]);
      
      //category list
      const getCatogery = () => {
        const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
        axios
          .get(`${BASE_Url}/item/category-list/${saasId}`)
          .then((res) => {
            setCategory(res.data.data);
          })
          .catch((err) => console.log(err));
      };
    
      useEffect(() => {
        getCatogery();
      }, []);


    const getBillOfMeterial = (id) => {
        const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
        axios
          .get(`${BASE_Url}/get-bill-of-material/${saasId}/${storeId}/${id}`)
          .then((res) => {
            if (res.data.status) {
              console.log(res);
              setBOMData(res.data.data);
              setModalShow(true)
            } else  {
              Swal.fire(res.data.message);
        
            }
          });
      };
      
 
  useEffect(() => {
    if (search) {
      const apiUrl = `${BASE_Url}/search/get-result/${storeId}/${saasId}/${search}`;
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            console.log("new Api", response);
            const data = response.data.data;
            console.log(" search", data);
            // Assuming you want to display the data for the fourth item (index 3)
            // setItemData(data.data.item_list[3]);
            setSelectMenu(data);
            // setSearch(data);
          } else {
            console.log("Failed to fetch data from the API.");
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      // recommend();
    }
  }, [search]);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };


    setScreenWidth(window.innerWidth); // Initialize screenWidth

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const getBorderColor = (nameLength) => {
    if (nameLength < 10) {
      return "5px solid #E1D98C";
    } else if (nameLength === 10) {
      return "5px solid powderblue";
    } else {
      return "5px solid lightgreen";
    }
  };
  const getBackgroundColor = (isActive) => {
    const defaultColor = "bg-zinc-200 text-md font-bold p-3 border-b-2 border-zinc-500 rounded";
    const activeColor = "catgorycolorActive  text-white";
    const hoverColor = "hover:bg-dark-purple hover:text-white";
    return `${defaultColor} ${isActive ? activeColor : ''} ${hoverColor}`;
  };
  return (

    <>
    <div className="container">
    <Grid container className="my-3 px-3" spacing={2} alignItems="center">
      {/* <Grid item xs={12} sm={6}>
        <Select
          fullWidth
          value={state}
          onChange={(e) => setState(e.target.value)}
          aria-label="Default select example"
        >
          <MenuItem value="Inventory">Inventory</MenuItem>
          <MenuItem value="Bill of Materials">Recipe</MenuItem>
        </Select>
      </Grid> */}
      {/* <Grid item xs={12} sm={2}>
        {state === 'Bill of Materials' ? (
          <Button
            fullWidth
            variant="contained"
            style={{background:"black"}}
            onClick={() => navigate('/addBOM')}
          >
           Add Recipe
          </Button>
         ) : (
          <Button 
          onClick={() => setInventryShow(true)}
          fullWidth variant="contained">Add Item</Button>
        )} 
      </Grid> */}
      <Button
            fullWidth
            variant="contained"
            style={{background:"black"}}
            onClick={() => navigate('/addBOM')}
          >
           Add Recipe
          </Button>
    </Grid>
      <div className="row">
        
        
          <> <Grid container className="mt-1" spacing={2}>
      <Grid item xs={12} sm={12}>
      <Paper
            component="form"
            sx={{
              height: "fit-content",
              p: "2px 4px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // width: 600,
              background: "#f2f1f1",
           
            }}
          
          >
            <IconButton
              type="submit"
              sx={{ p: "10px" }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search Items"
              inputProps={{ "aria-label": "search Items" }}
            />

            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          </Paper>
     
      </Grid>
    </Grid>

           
          <Grid container spacing={1} >
          <Grid className="mt-1" item xs={12} sm={2}>
          <div
                className={`  ${screenWidth >= 768 ? 'bg-zinc-200' : ''}  flex-1 mt-3 fw-bold text-blue rounded category-container`}
                style={{

                  height: screenWidth >= 768 ? '550px' : 'auto',
                  overflowY: screenWidth >= 768 ? 'auto' : 'hidden',
                }}
              >
                 <div className="category-items"
                  style={{
                    display: 'flex',
                    flexDirection: screenWidth >= 768 ? 'column' : 'row',
                    overflowX: screenWidth >= 768 ? 'hidden' : 'auto',
                  }}>
    {/* Content of the left column */}
    {category.length > 0 &&
      category.map((list) => (
        <div
        className={`flex items-center justify-center ${getBackgroundColor(activeCategory === list.category_id)}`}
                            onClick={() => {
                              setActiveCategory(list.category_id);
                              setID(list.category_id);
            setSendCategory(list.category_name);
          }}
        >
          {list.category_name}
        </div>
      ))}
  </div></div>
  </Grid>


  <Grid style={{  height:"80%", overflowY: "auto"}} className="  max-sm:grid justify-center gap-0   "  item xs={12} sm={10}>
  <div className="grid grid-cols-5 gap-0 max-sm:grid-cols-2 justify-center  max-w-full  "  >
    {/* Content of the right column */}
    {selectMenu?.length > 0 &&
      selectMenu.map((el) =>
        
        (
        <button
       
        style={{
          width: "150px",
          height: "50px",
          borderRadius: "1px",
          boxShadow: "0px 0px 9px 0px rgba(0, 0, 0, 0.25) inset",
          fontFamily: "DM sans-serif",
          fontSize: "1rem",
          borderLeft: getBorderColor(el.item_name?.length),
          marginBottom: "10px", // Adjust margin for spacing
        }}
        className="bg-zinc-200 m-2 p-0 fw-bold bg-white text-gray-700 "
          onClick={() => {
            setShowItem(el.item_name);
            getBillOfMeterial(el.item_id);
          }}
        >
          {el.item_name}
        </button>
      ))}
  </div>
</Grid>
</Grid>


     



            <div className="col-6">
              {BOMData?.length > 0 && (
                <table className="table" style={{ height: "20px" }}>
                  <thead>
                    <tr>
                      <th scope="col">Raw Materials</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {BOMData.map((el) => (
                      <tr>
                        <td>{el.raw_material}</td>
                        <td>{el.quantity}</td>
                        <td>{el.cost_of_material}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        
        
      </div>
    </div>
    <InventoryModal show={modalShow} onHide={() => setModalShow(false)} data={BOMData} Showitem={Showitem} />

      {/* <AddInventoryItemModal  show={inventryShow} onHide={() => setInventryShow(false)} /> */}
    </>
  );
};

export default Recipe;

// import Navbar from '../Navbar/Navbar'
import axios from "axios";
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";

// import Col from 'react-bootstrap/Col';
// import Row from 'react-bootstrap/Row';
import "bootstrap/dist/css/bootstrap.min.css";
// import Update from './Update'
import { Link, useNavigate } from "react-router-dom";
import { BiSolidChevronRight } from "react-icons/bi";
import { BASE_Url } from "../../URL.js/URL";

function OrderPage() {
  const [itemData, setItemData] = useState([]);
  const [table, setTable] = useState([]);
  const navigate = useNavigate();
  const getTables = () => {
    const { storeId, saasId } = JSON.parse(localStorage.getItem("STORE_DATA"));
    const apiUrl = `${BASE_Url}/tableview/table_view/${saasId}/${storeId}`;

    // Make the API call
    axios
      .get(apiUrl)
      .then((response) => {
        console.log(response);
        setTable(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getTables();
  }, []);
  const handleNavigate = (el) => {
    if (el.status === "Reserved") {
      navigate(`/SelectOrders`);
    } else if (el.status === "Occupied") {
    } else if (el.status === "Empty") {
      navigate(`/SelectOrders/${el.table_name}/${el.table_id}`);
    }
  };

  return (
    <div>
      <div className="flex  w-full mt-1 justify-around fw-bold "
       style={{
        boxShadow: '0 2px 12px rgba(36, 36, 39, 0.12)',
        borderRadius: '0.75rem',
        overflow: 'hidden',
        fontFamily: "DM sans-serif",
        fontSize: "1.125rem",
        border: "0 2px 12px rgba(36, 36, 39, .12)", padding: "20px"
       
      }}>
   
        <div className="flex items-center justify-end items-center ">
          <p className="grey m-0 p-0"></p>
          &nbsp;
          <p className="text-lg m-0 p-0">Empty</p>
        </div>
        <div className="flex items-center justify-center">
          <p className="yellow m-0 p-0 text-lg"></p>
          &nbsp;
          <p className="text-lg m-0 p-0">Occupied</p>
        </div>
        <div className="flex items-center justify-center">
          <p className="green m-0 p-0"></p>
          &nbsp;
          <p className="text-lg m-0 p-0">Reserved</p>
        </div>
      </div>
      <div className="flex flex-col mt-4 m-8 ml-50" style={{ height: "75vh", overflowY: "auto" }}>

      
        <div className="flex justify-between  gap-15 w-full">
          <div className="left-2 flex-col items-center justify-center h-6 top-80 w-[200]  text-blue-500">
            {table.map((el) => (
              <div className="flex flex-col ">
                 <div className="my-2 fw-bold  text-white "
              ><button  style={{background:"#0E2954"}}  className=" text-white py-2 px-4 rounded md:mt-0"> {el.category}</button></div>

                <div className="flex flex-row flex-wrap">
                  {el.table_list.map((el1) => (
                    // <div
                    //   key={el1.table_id}
                    //   style={{
                    //     height: "65px",
                    //     // border: `4px solid ${
                    //     //   el1.status === "Occupied"
                    //     //     ? "#d97706" 
                    //     //     : el1.status === "Available"
                    //     //     ? "#65a30d" 
                    //     //     : "rgb(117, 116, 114)"
                    //     // }`,
                    //     position: "relative",
                    //     // borderRadius: 70,
                    //     color: "#000",
                    //     fontFamily: "Roboto",
                    //     fontSize: "16px",
                    //     fontStyle: "normal",
                    //     fontWeight: "700",
                    //     lineHeight: "normal"
 
                    //     // width: "9%", // Adjusted width for small screens
                    //   }}
                    //   className="flex flex-col w-16 h-16 border-2 border-gray-300 flex items-center justify-center relative rounded-md shadow-md m-2"
                    //   onClick={() => handleNavigate(el1)}

                    // >
                    //   <span className="text-xl"> {el1.table_name}</span> 
                    //   <div className={`absolute bottom-0 left-0 right-0 h-2 ${el1.status === "Occupied"
                    //         ? "bg-amber-600"
                    //         : el1.status === "Available"
                    //           ? "bg-lime-600"
                    //           : "bg-zinc-500"
                    //       } rounded-b-md`}></div>
                    // </div>
                    <div
                    key={el1.table_id}
                    onClick={() => handleNavigate(el1)}
                    className={`cursor-pointer w-24 h-24 border-3 border-[gray] border-dotted flex flex-col items-center justify-center relative m-2 rounded-lg shadow-md ${
                      el1.status === 'Occupied' ? 'bg-[#ffab4b]' : 'bg-gray-100'
                    }`}
                  >
                    {/* Time */}
                    {/* {table.time && <span className="absolute top-2 left-2 text-sm">{table.time}</span>} */}
          
                    {/* Table Number */}
                    <span className="text-lg text-black">{el1.table_name}</span>
          
                    {/* Price */}
                    {el1.total_amount && <span className="text-sm mt-1 text-black">{el1.total_amount}</span>}
          
                    {/* Icon (Different icons based on status) */}
                    {/* <div className="absolute bottom-0">
                      {el1.status === 'Occupied' && (
                        <RemoveRedEyeOutlinedIcon className="h-6 w-6 text-gray-700"/>
                      ) }
                    </div> */}
                  </div>
                  ))}
                </div>

              </div>
            ))}
          </div>


        </div>
        <ul className="flex flex-wrap">
          {itemData.map((data) => {
            // console.log("order1",data)

            return (
              <div
                className=" w-full max-w-[18rem] rounded-lg border-2 border-gray-300  bg-white m-8  "
                style={{ height: "100vh", overflowY: "auto" }}
              >
                <div className="flex">
                  <li className="w-full border-b-2 font-medium text-xl border-gray-300  px-4 py-3 p-6">
                    {data.table_name}
                  </li>
                  {/* <button type='button' className="inline-block rounded bg-light-yellow px-6 pb-2 pt-2.5  mr-3 text-xs font-medium   text-black ">FoodReady</button> */}
                  <BiSolidChevronRight className="items-center m-6 font-bold"></BiSolidChevronRight>
                </div>

                <div className="flex ">
                  <li className="w-full  font-semibold text-xl px-4 py-3">
                    {data.customer_name}
                  </li>
                  <li className="  font-semibold text-xl  flex items-center justify-between px-4 py-3">
                    {data.time}
                  </li>
                </div>

                <li className="w-full   font-thin px-4 py-3 border-b-2">
                  {data.category}{" "}
                </li>

                <div className="flex ">
                  <li className="w-full  font-semibold text-xl px-4 py-3">
                    Items
                  </li>
                  <button
                    type="button"
                    className="inline-block rounded bg-light-yellow px-3 py-3 pb-2 pt-2.5  m-3 text-xs font-medium   text-black "
                  >
                    {data.status}
                  </button>
                </div>

                <div>
                  {/* onClick={()=>Update(data)}  */}
                  <ul>
                    {data.item_name_list.map((item) => {
                      return (
                        <li className="px-4 mt-2 mb-2 ">{item.item_name}</li>
                      );
                    })}
                  </ul>

                  {/* <li
                 className="w-full border-b-2 border-gray-300  px-4 py-3 pb-14">
                 Chinese Maggi
                 (Full)-1
               </li> */}
                </div>

                <div className="flex sticky bottom-0">
                  <div className=" font-bold text-xl  item-center  border-t-2 px-4 ">
                    <button
                      className="mt-2 mb-2  items-center border-r-2 px-3 pl-9"
                      onClick={() => navigate(`/Update?orderId=${data.order_id}`)}
                    >
                      Update{" "}
                    </button>
                  </div>
                  <div className=" font-bold text-xl  item-center  border-t-2 px-4 ">
                    <button
                      className="mt-2 mb-2  items-center  px-4"
                      disabled={data.preparing === true}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </ul>
      </div>
    </div>


  );
}

export default OrderPage;
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import chola from "../../images/chola.jpg";
import axios from 'axios';
import { BASE_Url } from "../../URL.js/URL";
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import {  BsTrash3 } from "react-icons/bs";
import { Button } from '@mui/material';
const Cart = ({show,setShow,cartData,setCartData,setCount,table_id,catgoryName}) => {
    const {bankAccount} = JSON.parse(localStorage.getItem('STORE_DATA'))
    const handleClose=()=>{
        setShow(false)
    }
    
    
     
    const updateCartInLocalStorage = (updatedCart) => {
      localStorage.setItem("my-cart", JSON.stringify(updatedCart));
    };
    
    const {saas_id,store_id}= useParams()

    const increment =(item)=>{
      const q = item.item_qty + 1;
    item.item_qty = q;
    const newP = item.item_price * q;
    item.new_price = newP;

    cartData.map((item) => {
      item.item_newprice = item.item_price * item.item_qty
    });
    setCartData([...cartData])
    updateCartInLocalStorage(cartData)
    }

    //decriment function
    const decrement =(item)=>{
     if(item.item_qty ===1){
      item.item_qty=1
      item.item_price=item.item_price
     }else{
      const q = item.item_qty - 1;
      item.item_qty = q;
      const newP = item.item_price * q;
      item.new_price = newP;
    }
    cartData.map((item) => {
      item.item_newprice = item.item_price * item.item_qty
    });
    setCartData([...cartData])
    updateCartInLocalStorage(cartData)
    }


   const ConsfirmOrder =()=>{
    if(cartData.length > 0){
      
    axios
    .post(`${BASE_Url}/order/create`, {
      order_date: "2023-08-22",
     saas_id: saas_id,
     table_id:table_id,
     store_id: store_id,
     status: "pending",
     order_type: "Dine in",
      order_details:cartData,
      add_customer: [], 
      approval_status:"pending"   })
    .then((res) => {
      console.log("SAVE CREATE ORDER", res);
      if (res.status === 200) {
        console.log("this is my order",res.data.data)
        localStorage.setItem("my-order", JSON.stringify(res.data.data));
        localStorage.removeItem("my-cart");
        Swal.fire("Good job", `Your Order is confirmed `, "success");
        setCartData([]);
        setCount(0)
        handleClose()
      }
    });
  }else{
    Swal.fire("Not Allow", `please add some item in cart `, "error");
  }
   }

   const handleDeleteCartItem = (item) => {
    const getData = JSON.parse(localStorage.getItem("my-cart"));
    console.log(getData);
    if (getData) {
      if (getData?.length > 0) {
        if (getData?.length > 1) {
          const updateCart = getData.filter((el) =>
            el.item_id
              ? el.item_id !== item.item_id
              : el.productId !== item.productId
          );

          localStorage.setItem("my-cart", JSON.stringify(updateCart));
          setCartData(updateCart);
          // dispatch(handlecartCount(updateCart?.length));
        } else {
          localStorage.removeItem('my-cart');
          setCartData([]);
          // dispatch(handlecartCount(0));
        }
      }
    }
  };
  return (
    <>
    <Modal show={show} onHide={handleClose} animation={false}
       aria-labelledby="contained-modal-title-vcenter"
       centered>
      <Modal.Header closeButton>
        <Modal.Title>My Basket</Modal.Title>
      </Modal.Header>
      <Modal.Body>{
        
        cartData && cartData.map((item)=>(

        <div className="flex flex-row items-center bg-white border   md:flex-row mt-1 ">
                    <div className="mx-1 h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                      <img className="h-full w-full object-cover object-center"  src={`${BASE_Url}/item/get-image/${item.item_id}`} />
                    </div>
                   
                    <div className="flex flex-col justify-betweenleading-normal mx-2 mb-1" >
                      <p className="mb-2  font-medium lowercase tracking-tight text-gray-900 dark:text-white fw-bolder"
                        style={{position: "relative",
                        top: "20px"}}
                      >{item.item_name}</p>
                     <div className='d-flex'
                      style={{ 
                       position:" relative",
                       top: "70px",
                       right: "-162px"}}>
                        <BsTrash3 onClick={()=>{handleDeleteCartItem(item)}}/></div> 
                      <p className="mt-1 text-sm text-gray-500">Qty:{item.item_qty}</p>
                      <div className="inline-flex h-fit ">
                        <button style={{   boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderBottomLeftRadius: "0.75rem",
              borderTopLeftRadius: "0.75rem",

              
              }} className="bg-dark-purple text-xl hover:bg-gray-400 text-white font-bold py-1 px-3 rounded-l" onClick={() => increment(item)}>
                          +
                        </button>
                        <input value={item.item_qty} className="w-10 text-xl  text-center border-solid border-2 "></input>
                        <button 
                        
                        style={{   boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderBottomRightRadius: "0.75rem",
              borderTopRightRadius: "0.75rem",

              
              }} 
                        className="bg-dark-purple text-xl hover:bg-gray-400 text-white font-bold py-1 px-3 rounded-r" onClick={()=>{decrement(item)}}>
                          -
                        </button>
                      </div>
                    
                    </div>
                    <h5 className="mb-2 text-xl font-medium tracking-tight text-gray-900 dark:text-white  pr-4" 
                    style={{position: "relative",
                      right: "88px",
                      top: "5px"}}
                    >{bankAccount}{item?.item_newprice ?item?.item_newprice:item?.item_price}</h5>
                  </div>
               )) }</Modal.Body>
               <Modal.Footer>
      <div className='d-flex justify-around'>
        <Button className='bg-secondary text-white' variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
                   className='text-nowrap'   type="button"
                      style={{
                        backgroundColor: "rgb(169, 10, 10)",
                        border: "none",
                        color: "white",
                        fontWeight: "bold",
                      
                      }}
                      // id="pop112"
                      onClick={() => {
                        localStorage.removeItem("my-cart");
                        setCartData([]);
                        handleClose()
                      }}
                    >
                      Remove All
                    </Button>
        <Button className='bg-primary text-white  text-nowrap' variant="primary" onClick={ConsfirmOrder}>
          Confirm Order
        </Button>
      </div></Modal.Footer>
    </Modal>
  </>
  )
}

export default Cart
// import React, { useState } from "react";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
// import TabPanel from "@mui/lab/TabPanel";
// import TabContext from "@mui/lab/TabContext";
// import Box from "@mui/material/Box";

// const Products = [
//   { Name: "Coke", QuantitySold: 200, TotalAmount: 4000 },
//   { Name: "Tea", QuantitySold: 200, TotalAmount: 4000 },
//   { Name: "Sprite", QuantitySold: 200, TotalAmount: 4000 },
//   { Name: "Mirinda", QuantitySold: 200, TotalAmount: 4000 },
// ];

// const Reports = () => {
//   const [value, setValue] = useState("1");

//   const handleChange = (event, newValue) => {
//     setValue(newValue);
//   };
//   return (
//     <>
//       <div className="flex flex-col mt-4">
//         <h3 className="text-start p-2 m-4 font-medium text-xl font-['inter']">
//           Reports
//         </h3>
//         <div>
//           <div className="max-w-screen-lg lg mx-10 m-1 max-h-screen  border rounded border-gray-300">
//             <div>
//               {/* if not working apply this in div @line 21 className="w-full  mt-4 border-l-stone-900 bg-light-grey" */}
//               <TabContext className="w-full" value={value}>
//                 <Box>
//                   <Tabs
//                     className="w-full flex justify-between"
//                     value={value}
//                     onChange={handleChange}
//                     aria-label="simple tabs example"
//                   >
//                     <Tab
//                       className="text-white text-lg flex items-center gap-x-4 cursor-pointer p-0 m-0 rounded-sm hover:bg-dark-purple hover:text-white mt-4"
//                       label="Order Related Reports"
//                       value="1"
//                     />
//                     <Tab
//                       className="text-white text-lg flex items-center gap-x-4 cursor-pointer p-0 m-0 rounded-sm hover:bg-dark-purple hover:text-white mt-4"
//                       label="Table Wise Reports"
//                       value="2"
//                     />
//                     <Tab
//                       className="text-white text-lg flex items-center gap-x-4 cursor-pointer p-0 m-0 rounded-sm hover:bg-dark-purple hover:text-white mt-4"
//                       label="Category Wise Reports"
//                       value="3"
//                     />
//                     <Tab
//                       className="text-white text-lg flex items-center gap-x-4 cursor-pointer p-0 m-0 rounded-sm hover:bg-dark-purple hover:text-white mt-4"
//                       label="Item Related Reports"
//                       value="3"
//                     />
//                   </Tabs>
//                   <TabPanel
//                     value="1"
//                     // className="bg-white rounded-lg shadow-md p-4 max-w-sm mx-auto"
//                   >
//                     <div className=" grid gap-4  grid-cols-2">
//                       {Products.map((e) => {
//                         return (
//                           <div className="">
//                             <div className="p-2 mt-2 border rounded border-gray-300">
//                               <h2 className="text-xl  font-semibold mb-2  ">
//                                 Coke
//                               </h2>
//                               <span className="flex gap-x-16">
//                                 <p className="text-gray-600">Quantity Sold</p>

//                                 <p className="gap-4">200</p>
//                               </span>
//                               <span className="flex gap-11">
//                                 <p className="text-gray-600">Total Amount($)</p>
//                                 {/* git change */}
//                                 <p>4000</p>
//                               </span>
//                             </div>
//                           </div>
//                         );
//                       })}
//                     </div>
//                     {/* <div className="p-6  flex-wrap mt-2 border rounded border-gray-300">
//                   <h2 className="text-xl font-semibold mb-2 ">Tea</h2>
//                   <span className="flex gap-10">
//                     <p className="text-gray-600">Quantity Sold</p>

//                     <p>200</p>
//                   </span>
//                   <span className="flex gap-6">
//                     <p className="text-gray-600">Total Amoutn($)</p>

//                     <p>4000</p>
//                   </span>
//                 </div> */}
//                     {/* <div className="p-6  flex-wrap mt-2 border rounded border-gray-300">
//                   <h2 className="text-xl font-semibold mb-2 ">Sprite</h2>
//                   <span className="flex gap-10">
//                     <p className="text-gray-600">Quantity Sold</p>

//                     <p>200</p>
//                   </span>
//                   <span className="flex gap-6">
//                     <p className="text-gray-600">Total Amoutn($)</p>

//                     <p>4000</p>
//                   </span>
//                 </div> */}
//                     {/* <div className="p-4 bg-gray-100 rounded-b-lg"></div>
//               <div className="p-4">
//                 <h2 className="text-xl font-semibold mb-2 ">Coke</h2>
//                 <span>
//                   <p className="text-gray-600">Quantity Sold</p>
//                   <p>200</p>
//                 </span>
//               </div>
//               <div className="p-4 bg-gray-100 rounded-b-lg"></div>
//               <div className="p-4">
//                 <h2 className="text-xl font-semibold mb-2 ">Coke</h2>
//                 <span>
//                   <p className="text-gray-600">Quantity Sold</p>
//                   <p>200</p>
//                 </span>
//               </div>
//               <div className="p-4 bg-gray-100 rounded-b-lg"></div> */}
//                   </TabPanel>
//                   <TabPanel value="2">Content for Tab 2</TabPanel>
//                   <TabPanel value="3">Content for Tab 3</TabPanel>
//                 </Box>
//               </TabContext>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Reports;

import React, { useState } from "react";

const Counter = ({ qty, index, handleQtyChange }) => {
  const [count, setCount] = useState(0);

  const Increment = () => {
    setCount(count + 1);
  };
  const Decrement = () => {
    setCount(count - 1);
  };
  return (
    <div className="flex">
      <button className="mx-1" onClick={() => handleQtyChange(index, qty - 1)}>
        -
      </button>
      {qty}
      <button className="mx-1" onClick={() => handleQtyChange(index, qty + 1)}>
        +
      </button>
    </div>
  );
};

export default Counter;

import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import chola from "../../images/chola.jpg";
import Navbar from "../Opt/Navbar";
import Product from "../Opt/Product";
import Cart from "../Opt/Cart";
import { AiOutlineClose, AiOutlineProfile, AiOutlineShoppingCart } from "react-icons/ai";
import { BsBucketFill } from "react-icons/bs";
import { IoReceiptSharp } from "react-icons/io5";
import { BASE_Url } from "../../URL.js/URL";
import { RxCross2 } from "react-icons/rx";
import { FiMenu } from "react-icons/fi";
import { Box, Modal } from "@mui/material";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import Swal from "sweetalert2";
function Beverages() {
  const params = useParams();
  const { table_id, id, saas_id, store_id } = params;
  const [menu, setMenu] = useState("");
  const [open, setOpen] = useState("");
  const [category, setCategory] = useState("");
  const [updatecart, setUpdateCart] = useState(true);
  const [cartData, setCartData] = useState(null);
  const [count, setCount] = useState(0);
  const [catgoryName, setCatgorynaem] = useState();
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [PdfFile, setPDFFile] = useState("");
  const [viewPdf, setViewPdf] = useState(false);
  const [totalsum, setTotalSum] = useState(0)
  //search api intigreation
  const [recommandItem, setRecommandItem] = useState("");
  useEffect(() => {
    if (search) {
      const apiUrl = `${BASE_Url}/search/get-result/80001/8/${search}`;
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            console.log("new Api", response);
            const data = response.data.data;
            console.log(" search", data);
            // Assuming you want to display the data for the fourth item (index 3)
            // setItemData(data.data.item_list[3]);
            setRecommandItem(data);
            // setSearch(data);
          } else {
            console.log("Failed to fetch data from the API.");
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      CatgoryData();
    }
  }, [search]);

  const CatgoryData = () => {
    const api = `${BASE_Url}/item/view-menu/${id}/${saas_id}`;
    axios
      .get(api)
      .then((response) => {
        if (response.status === 200) {
          console.log(response, "from fetchdata");
          const data1 = response.data.data.item_list;
          console.log(" menu", response.data.data.category_name);
          setCatgorynaem(response.data.data.category_name);
          setRecommandItem(data1);
        } else {
          console.log("Failed to fetch data from the API.");
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  useEffect(() => {
    CatgoryData();
  }, [params]);
  console.log(params);
  useEffect(() => {
    const t1 = JSON.parse(localStorage.getItem("my-cart"));
    let finalSum = 0;
    let itemTotalPrice;
    t1?.map((item) => {
      itemTotalPrice = item.item_newprice
        ? item.item_newprice
        : item.item_price * item.item_qty;
      finalSum += itemTotalPrice;
      setTotalSum(finalSum);
    });
    setCartData(t1);
    setCount(t1?.length);
    console.log(t1);
  }, [updatecart]);
    
  // this is resciete function 
  const myorders = () => {
    const data= JSON.parse(localStorage.getItem("my-order")) 
    console.log("this is order_id",data?.order_id)
      if (data?.order_id) {
       
        axios.get(
          `${BASE_Url}/order/get-invoice-for-order/${data.order_id}/${saas_id}/${store_id}`
        ).then((res)=>{
          // console.log("invoice", res.data.data.invoice );
          setPDFFile(res?.data?.data?.invoice);
          console.log("pdf file",PdfFile)
          // setQr(res.data?.data?.qr_file_name); 
          if (res?.data?.data?.invoice) {
            setViewPdf(true);
            localStorage.removeItem('my-order')
          }else{
            Swal.fire(
              "Please Wait",
              `Your Invoice not Generated Now`,
              "info"
            );
          }
        })
      }else{
        Swal.fire(
          "Please Place New Order",
          `Need to Place Order`,
          "info"
        ); 
      }
    
};
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  height: 575,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MyTotalbill= () =>{
  const data = JSON.parse(localStorage.getItem("my-order")) 
  console.log('total bill',data?.order_id )
  if(data?.order_id){
    axios
    .get(
      `${BASE_Url}/order/total-bill-amount/${data?.order_id}`
    )
    .then((res) => {
      if (res.status === 200) {
        console.log("recommended DATA", res.data.data.total_bill_amount);
        Swal.fire(`Your Total Amount: Rs.${res.data.data.total_bill_amount}`)
      }
    })
    .catch((err) => console.log(err));

  }else{
    Swal.fire(
      "Please Place New Order",
      `Need to Place Order`,
      "info"
    ); 
  }
}
const [activeIcon, setActiveIcon] = useState(null);
  return (
    <div>
      <Navbar setRecommandItem={setRecommandItem} saas_id={saas_id} store_id={store_id} table_id={table_id} />
      {show && (
        <Cart
          show={show}
          setShow={setShow}
          updatecart={updatecart}
          cartData={cartData}
          setCartData={setCartData}
          setCount={setCount}
          table_id={table_id}
        />
      )}
       <Modal
            open={viewPdf}
            // onClose={}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setViewPdf((state) => !state);
                  // navigate("/");
                }}
              >
                <AiOutlineClose size={20} />
              </div>
              {PdfFile && (
                <div style={{ height: "400px" }}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                    <Viewer
                      fileUrl={`${BASE_Url}/transaction/pdf/${PdfFile}`}
                      plugins={[defaultLayoutPluginInstance]}
                    />
                  </Worker>
                </div>
              )}
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <button className="bg-dark-subtle mt-10 p-2" onClick={()=>setViewPdf(false)}>
                 Close
                </button>
              
              </div>
            </Box>
          </Modal>
      <li className="flex  border-b-2">
        <div className=" flex flex-1 flex-col">
        
          <Product
            catgoryName={catgoryName}
            recommandItem={recommandItem}
            updatecart={updatecart}
            setUpdatecart={setUpdateCart}
          />
        </div>
      </li>
      <footer
            style={{
              width: "100%",
              boxShadow: "0 2px 12px rgba(36, 36, 39, 0.12)",
              borderRadius: "0.75rem",
              padding: "10px",
              backgroundColor: "rgb(245 158 11)",
              height:"80px"
            }}
            className="
            container
            d-flex
            justify-between
            text-white
             fixed
             bottom-0"
          >
            {cartData?.length > 0 ? (
              <>
                {" "}
                <div className="d-flex flex-column">
                <div className=" css-de1c49">
                  <AiOutlineShoppingCart
                    style={{ height: "25px", fontSize: "26px" }}
                    onClick={() => setShow(true)}
                  />
                  <span className="fw-bold mx-1" style={{fontFamily:"sans-serif"}}> ( { count } )</span>
                </div>
                  <span className="fw-bold" style={{fontFamily:"sans-serif"}}>RS. {totalsum}</span>

                </div>

                <span style={{fontFamily:"sans-serif"}} className="fw-bold cursor-pointer" onClick={() => setShow(true)}>
                  View Cart
                </span>
              </>
            ) : (
              <div className="css-de1c49">
                <div onClick={MyTotalbill}>
                  <div className="flex justify-center cursor-pointer">
                  <BsBucketFill style={{  fontSize: "26px" }} />  </div>
                  <span  className="semibold text-nowrap">Your bill</span>
                </div>
                <div style={{ marginLeft: "80px" }} onClick={()=>{navigate(`/Order-status/${saas_id}/${store_id}/${table_id}`)}}>
                <div className="flex justify-center cursor-pointer">
                   <AiOutlineProfile
                    style={{ fontSize: "26px" }}
                  /></div>  
                  <span className="semibold text-nowrap">Order Status</span>
                </div>
                <div onClick={myorders} style={{ float: "right", marginLeft: "60px" }}>
                <div className="flex justify-center cursor-pointer">
                  <IoReceiptSharp
                    style={{ fontSize: "26px" }}
                  />  </div>
                  <span  className="semibold text-nowrap">Receipts</span>
                </div>
              </div>
            )}
          </footer>
    </div>
  );
}

export default Beverages;

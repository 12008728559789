import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { FiMenu } from 'react-icons/fi';
import { RxCross2 } from 'react-icons/rx';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_Url } from '../../URL.js/URL';
import SearchIcon from "@mui/icons-material/Search";
import { Divider, IconButton, InputBase, Paper } from "@mui/material";
import CategorySwiper from './CategorySwiper';


const Navbar = ({ saas_id, table_id, store_id ,setRecommandItem,recommend}) => {
  const navigate = useNavigate();
  let [open, setOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const getCategory = () => {
    axios
      .get(`${BASE_Url}/item/category-list/${saas_id}`)
      .then((res) => {
        if (res.status === 200) {
          // console.log("CATEGORY DATA", res);
          setCategory(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };



  const handleData = (link) => {
    navigate(`/category-name/${saas_id}/${store_id}/${link.category_name}/${link.category_id}/${table_id}`)
    setOpen(false)
  }


  const [Store, SetStore]= useState()
  const StoreName = async () => {
    const response = await axios.get(`${BASE_Url}/transaction/Store_name/${saas_id}/${store_id}`)
 SetStore(response?.data?.data?.storeName)
    console.log("this is res",response)

  }
  useEffect(() => {
    StoreName()
    getCategory();
  }, []);

  const [search, setSearch] = useState("");
  useEffect(() => {
    if (search) {
      const apiUrl = `${BASE_Url}/search/get-result/${store_id}/${saas_id}/${search}`;
      axios
        .get(apiUrl)
        .then((response) => {
          if (response.status === 200) {
            console.log("new Api", response);
            const data = response.data.data;
            console.log(" search", data);
            // Assuming you want to display the data for the fourth item (index 3)
            // setItemData(data.data.item_list[3]);
            setRecommandItem(data);
            // setSearch(data);
          } else {
            console.log("Failed to fetch data from the API.");
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    } else {
      // recommend();
    }
  }, [search]);

  
  return (
    <>
       <CategorySwiper
        category={category}
        saas_id={saas_id}
        store_id={store_id}
        table_id={table_id}
      />
      <div className="md:flex bg-dark-purple py-6 md:px-10 px-7">
        
        <div className="font-bold text-2xl cursor-pointer flex item-center font-[poppins] text-gray-800">
        <Paper
                  component="form"
                    // onSubmit={(e) => {
                    //   getSearchCustomer(e);
                    // }}
                  sx={{
                    height: "40px",
                    p: "2px 4px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 400,
                    background: "#f2f1f1",
                    border: "2px solid white",
                    borderRadius: "10px",
                  }}
                >
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search "
                    inputProps={{
                      "aria-label": "Search ",
                    }}
                  />
                  <IconButton
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                   
                 
                  >
                    <SearchIcon  />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical"  />
                </Paper>
          {/* <Link to={`/${saas_id}/${store_id}/${table_id}`} className="text-3xl font-extrabold text-white">{Store}</Link> */}
        </div>
  

      </div>
   


    </>
  )
}

export default Navbar